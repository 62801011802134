import {SET_NOTIFICATION_STATE, SET_COACH_NOTIFICATION, SET_AFFIRMATION_NOTIFICATION, SET_JOURNAL_NOTIFICATION} from './../constants/actionTypes'

const initialState = {
    coachingNotification: false,
    affirmationNotification: false,
    journalNotification: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATION_STATE:
            return {
                ...state,
                ...action.payload
            };

        case SET_COACH_NOTIFICATION:
            return {
                ...state,
                coachingNotification: action.payload.coachingNotification,
            };

        case SET_AFFIRMATION_NOTIFICATION:
            return {
                ...state,
                affirmationNotification: action.payload.affirmationNotification,
            };

        case SET_JOURNAL_NOTIFICATION:
            return {
                ...state,
                journalNotification: action.payload.journalNotification,
            };

        default:
            return state;
    }
}
