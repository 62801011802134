import { apiSlice } from '../../../apiSlice';

export const coachApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserInformationState: builder.query({
      query: () => `life-coaches/cs-user-information-state/`,
      keepUnusedDataFor: 2,
    }),
    chatAssistant: builder.mutation({
      query: (params) => ({
        url: 'life-coaches/v2/cs_assistant/',
        method: 'POST',
        body: { ...params },
      }),
    }),
  }),
});

export const {
  useGetUserInformationStateQuery,
  useChatAssistantMutation,
} = coachApiSlice;
