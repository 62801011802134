import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';

import LifeCoachSessionComponent from './LifeCoachSessionComponent';
import LifeCoachCustomSession2Component from './LifeCoachCustomSession2Component';
import LifeCoachCustomSession3Component from './LifeCoachCustomSession3Component';
import LifeCoachCustomSession4Component from './LifeCoachCustomSession4Component';

import './../css/life-coach-session-popup.css'

export default function LifeCoachSessionPopup({show, handlePopupClose, session}) {

    return (

        <>
            {
                session &&
                <Modal
                    show={show}
                    onHide={handlePopupClose}
                    backdrop="static"
                    size="xl"
                    keyboard={false}
                    className='life-coach-session-popup'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{session.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {session.order == 1 &&
                        <LifeCoachSessionComponent session={session} handlePopupClose={handlePopupClose} />
                        }
                        {session.order == 2 &&
                        <LifeCoachCustomSession2Component session={session} handlePopupClose={handlePopupClose} />
                        }
                        {session.order == 3 &&
                        <LifeCoachCustomSession3Component session={session} handlePopupClose={handlePopupClose} />
                        }
                        {session.order == 4 &&
                        <LifeCoachCustomSession4Component session={session} handlePopupClose={handlePopupClose} />
                        }

                    </Modal.Body>
                </Modal>
            }

        </>
    );
}