import { SET_CREDENTIALS, LOG_OUT, ACCEPT_TERMS, SET_USER_ROLE, SET_PROFILE, UPDATE_AVATAR, SET_APP_LOGOUT_TIME, SET_UPGRADE_REMINDER_AT, SET_ENABLED_MY_UPLOAD_FILES, SET_ENABLED_REWARDS, SET_WATCHED_LIFE_COACH_TUTORIAL, SET_CHECKED_USER_STATUS, SET_OPENAI_STATUS_CHECK_TIME } from './../constants/actionTypes'

const localUserData = localStorage.getItem('user')
const localUserStatus = localStorage.getItem('userStatus')

const userData = localUserData ? JSON.parse(localUserData) : null;
const initialState = {
    user: userData,
    token: userData ? userData.token : null,
    appLogoutTime: null,
    isEnabledMyUploadFiles: false,
    isEnabledRewards: false,
    isWatchedLifeCoachTutorial: false,
    isCheckedUserStatus: localUserStatus ? localUserStatus : false,
    openAiStatusCheckTime: 1
}
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CREDENTIALS:
            localStorage.setItem('user', JSON.stringify(action.payload));
            return {
                ...state,
                user: action.payload,
                token: action.payload.token
            };
        case LOG_OUT:
            localStorage.removeItem("user");
            return {};

        case SET_USER_ROLE:
            let currentUser = { ...state.user }
            currentUser.user_role_type = action.payload.user_role_type;
            currentUser.is_daily_reminder_checked = action.payload.is_daily_reminder_checked;
            localStorage.setItem('user', JSON.stringify(currentUser));
            return {
                ...state,
                user: currentUser,
            };
        case ACCEPT_TERMS:
            let newUser = { ...state.user }
            newUser.is_first_login = false;
            localStorage.setItem('user', JSON.stringify(newUser));
            return {
                ...state,
                user: newUser,
            };
        case SET_PROFILE:
            let profileUser = { ...state.user }
            profileUser.first_name = action.payload.first_name;
            profileUser.last_name = action.payload.last_name;
            profileUser.default_timezone = action.payload.default_timezone;
            localStorage.setItem('user', JSON.stringify(profileUser));
            return {
                ...state,
                user: profileUser
            };
        case UPDATE_AVATAR:
            let changeUser = { ...state.user }
            changeUser.avatar_url = action.payload.avatar_url;
            localStorage.setItem('user', JSON.stringify(changeUser));
            return {
                ...state,
                user: changeUser,
            };
        case SET_UPGRADE_REMINDER_AT:
            let upgradeUser = { ...state.user }
            upgradeUser.last_upgrade_remind_at = action.payload.last_upgrade_remind_at;
            localStorage.setItem('user', JSON.stringify(upgradeUser));
            return {
                ...state,
                user: upgradeUser,
            };

        case SET_APP_LOGOUT_TIME:
            return {
                ...state,
                appLogoutTime: action.payload
            };

        case SET_ENABLED_MY_UPLOAD_FILES:
            return {
                ...state,
                isEnabledMyUploadFiles: action.payload
            };
        case SET_ENABLED_REWARDS:
            return {
                ...state,
                isEnabledRewards: action.payload
            };
        case SET_WATCHED_LIFE_COACH_TUTORIAL:
            return {
                ...state,
                isWatchedLifeCoachTutorial: action.payload
            };
        case SET_CHECKED_USER_STATUS:
            localStorage.setItem('userStatus', true);
            return {
                ...state,
                isCheckedUserStatus: true
            };
        case SET_OPENAI_STATUS_CHECK_TIME:
            return {
                ...state,
                openAiStatusCheckTime: action.payload
            };

        default:
            return state;
    }
}

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
