import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Button } from "react-bootstrap";
import "./../css/life-coach-custom-session2-component.css";
import SessionMessageListComponent from "./SessionMessageListComponent";
import SessionChatInputComponent from "./SessionChatInputComponent";

import {
  useGetUserSessionMutation,
  useSendCustomSessionMessageMutation,
  useGenerateSessionSummaryMutation,
  useGetUserAnalyzedGoalsMutation,
  useAckChallengeMutation,
  usePossibleStrategiesMutation,
  useUpdateStrategiesMutation,
  useExtractChallengeStatementMutation,
} from "../slices/lifeCoachApiSlice";
import SessionBotProcessingComponent from "./SessionBotProcessingComponent";
import SessionWelcomeBackComponent from "./SessionWelcomeBackComponent";

const FAST_SPEED = 5;

const LifeCoachCustomSession3Component = ({ session, handlePopupClose }) => {
  const user = useSelector((state) => state.auth.user);
  const userLogoName = user.first_name.substring(0, 2).toUpperCase();
  const [goalData, setGoalData] = useState(null);
  const [currentGoalIdx, setCurrentGoalIdx] = useState(null);
  const [currentChallengeIdx, setCurrentChallengeIdx] = useState(null);
  const [forceRender, setForceRender] = useState(true);
  const [currentForceRender, setCurrentForceRender] = useState(true);

  const [userSessionHistory, setUserSessionHistory] = useState(null);
  const [messages, setMessages] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [sessionFinished, setSessionFinished] = useState(false);
  const [sessionEnded, setSessionEnded] = useState(false);
  const [isShowChat, setIsShowChat] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [currentChallenges, setCurrentChallenges] = useState([]);
  const [possibleChallenge, setPossibleChallenge] = useState(null);
  const [currentStrategies, setCurrentStrategies] = useState([]);
  const [selectedStrategies, setSelectedStrategies] = useState([]);
  const [additionChallenge, setAdditionChallenge] = useState(null);
  const [isUpdatedStrategy, setIsUpdatedStrategy] = useState(false);
  const [newsMessages, setNewsMessages] = useState([]);

  const [input, setInput] = useState("");
  const [step, setStep] = useState(null);
  const [currentChoice, setCurrentChoice] = useState("");
  const chatInputRef = useRef(null);
  const closeBtnRef = useRef(null);
  const scrollRef = useRef(null);

  const [getUserAnalyzedGoals] = useGetUserAnalyzedGoalsMutation();
  const [getUserSession] = useGetUserSessionMutation();
  const [sendSessionMessage] = useSendCustomSessionMessageMutation();
  const [generateSessionSummary] = useGenerateSessionSummaryMutation();
  const [ackChallenge] = useAckChallengeMutation();
  const [possibleStrategies] = usePossibleStrategiesMutation();
  const [updateStrategies] = useUpdateStrategiesMutation();
  const [extractChallengeStatement] = useExtractChallengeStatementMutation();

  useEffect(() => {
    if (goalData == null) {
      getUserAnalyzedGoals()
        .unwrap()
        .then(function (res) {
          if (res) {
            setGoalData(res);
            setCurrentGoalIdx(0);
            setCurrentChallengeIdx(0);
            if (userSessionHistory == null) {
              getUserSession({ session_id: session.id })
                .unwrap()
                .then(function (res) {
                  if (res.is_finished == false) {
                    setUserSessionHistory(res);
                    if (res.messages && res.messages.length > 0) {
                      setIsContinue(true);
                      setMessages(res.messages);
                      setStep(res.step);
                      setCurrentGoalIdx(res.goal_idx);
                      setCurrentChallengeIdx(res.challenge_idx);
                      if (res.challenges) {
                        setCurrentChallenges(res.challenges);
                      }
                      scrollToBottom();
                    } else {
                      setStep(0);
                    }
                  } else {
                    setSessionFinished(true);
                  }
                });
            }
          }
        });
    }
  }, []);

  const extractAckAndChallenges = (text) => {
    let flag = false;
    let ackText = "";
    let challengeText = "";
    const textLines = text.split("\n");
    for (let i = 0; i < textLines.length; i++) {
      let line = textLines[i];
      let pos = line.indexOf("@@Challenge@@");
      if (pos >= 0) {
        flag = true;
      }
      if (flag == false) {
        ackText += line.replace("@@Acknowledgement@@", "") + "\n";
      } else {
        challengeText += line.replace("@@Challenge@@", "") + "\n";
      }
    }
    ackText = ackText.trimStart().replace(/^:/, "").trim();
    challengeText = challengeText.trimStart().replace(/^:/, "").trim();
    return [ackText, challengeText];
  };

  const extractStrategies = (text) => {
    let entireText = text.replace("@@Possible Strategies@@", "");
    entireText = entireText.trim();
    let textLines = entireText.split("\n");
    let strategies = [];
    for (let i = 0; i < textLines.length; i++) {
      let line = textLines[i];
      line = line.trim();
      if (line != "") {
        if (/^[0-9]/.test(line)) {
          strategies.push(line);
        }
      }
    }
    return strategies;
  };

  useEffect(() => {
    if (newsMessages.length > 0) {
      setIsProcessing(true);
      sendSessionMessage({
        session_id: session.id,
        message: newsMessages,
        challenges: currentChallenges,
        is_finished: false,
        step: step,
        goal_idx: currentGoalIdx,
        challenge_idx: currentChallengeIdx,
      })
        .unwrap()
        .then(function (res) {
          console.log(res);
          setIsProcessing(false);
        });
    }
  }, [newsMessages]);

  useEffect(() => {
    const writeMessages = async (elements) => {
      setIsTyping(false);
      setIsShowChat(false);
      for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        let msg = element.content;
        let typeSpeed = 15;
        if (element.speed) {
          typeSpeed = element.speed;
        }
        console.log("element", element);
        if (element.effect == "type") {
          if (messages != null || i != 0) {
            if (element.type == "text" || element.type == "comment") {
              setMessages((prev) => [
                ...prev,
                {
                  role: "bot",
                  content: "",
                  type: "text",
                  effect: element.effect,
                  showLogo: element.showLogo,
                },
              ]);
            } else if (element.type == "radiogroup") {
              setMessages((prev) => [
                ...prev,
                {
                  role: "bot",
                  content: "",
                  type: "text",
                  choices: element.choices,
                  effect: element.effect,
                  showLogo: element.showLogo,
                },
              ]);
            } else if (element.type == "button") {
              setMessages((prev) => [
                ...prev,
                {
                  role: "bot",
                  content: "",
                  type: "text",
                  showLogo: element.showLogo,
                },
              ]);
            }
          } else {
            if (element.type == "text") {
              setMessages([
                {
                  role: "bot",
                  content: "",
                  type: "text",
                  showLogo: element.showLogo,
                },
              ]);
            } else {
              setMessages([
                {
                  role: "bot",
                  content: "",
                  choices: element.choices,
                  type: "text",
                  showLogo: element.showLogo,
                },
              ]);
            }
          }
          let charLen = 0;
          for (let char of msg) {
            charLen += 1;
            if (charLen > 100) {
              scrollRef.current?.scrollIntoView({ block: "end" });
              charLen = 0;
            }
            setMessages((prev) => {
              let newMessages = [...prev];
              newMessages[newMessages.length - 1].content += char;
              return newMessages;
            });
            await new Promise((resolve) => setTimeout(resolve, typeSpeed));
          }
          setMessages((prev) => {
            let newMessages = [...prev];
            newMessages[newMessages.length - 1].type = element.type;

            return newMessages;
          });
        } else if (element.effect == "put") {
          setMessages((prev) => [...prev, element]);
          await new Promise((resolve) => setTimeout(resolve, 25));
        } else if (element.effect == "delay") {
          await new Promise((resolve) =>
            setTimeout(resolve, 1000 * element.time)
          );
        } else if (element.effect == "set_step") {
          setStep(element.step);
        }
        setTimeout(() => {
          scrollToBottom();
        }, 100);
        setTimeout(() => {
          if (chatInputRef.current) {
            chatInputRef.current.focus();
          }
        }, 300);
      }
      setIsTyping(true);
    };
    if (step != null) {
      setIsShowChat(false);
      let elements = [];
      let content = "";
      let element = null;
      let newsMessage = [];
      if (step == 0) {
        content =
          "Welcome back, " +
          user.first_name +
          ", to your Mosaic Life coaching journey! Before we jump into today's session, let's take a moment to reflect on where we've been.";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newsMessage.push(element);
        elements.push(element);

        element = { role: "bot", content: "", effect: "delay", time: 1 };
        elements.push(element);

        content =
          "In our last session, we journeyed through the process of setting meaningful goals for your well-being. We explored different areas of your life and identified key aspirations that resonate with you. It was inspiring to see you take those steps towards self-improvement and growth!";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: false,
        };
        newsMessage.push(element);
        elements.push(element);

        element = { role: "bot", content: "", effect: "delay", time: 1 };
        elements.push(element);

        content =
          "Now, as we transition to Session 3, we're going to build on that foundation. Today is all about understanding the challenges that might come our way and crafting strategies to navigate them.";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: false,
        };
        newsMessage.push(element);
        elements.push(element);

        element = { role: "bot", content: "", effect: "delay", time: 1 };
        elements.push(element);

        content =
          "By the end of today's session, you'll have a deeper understanding of your challenges and a set of strategies to tackle them. When you're ready to take the next practical step towards achieving your goals, click the button below.";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: false,
        };
        newsMessage.push(element);
        elements.push(element);

        element = { role: "bot", content: "", effect: "set_step", step: 1 };
        elements.push(element);

        writeMessages(elements);
        setNewsMessages(newsMessage);
      } else if (step == 1) {
        if (forceRender == currentForceRender) {
          element = {
            role: "bot",
            content: "Let’s get started!",
            type: "button",
            effect: "type",
            showLogo: true,
          };
          elements.push(element);
          writeMessages(elements);
          return;
        }
        setCurrentForceRender(!currentForceRender);

        content =
          "Great! As we navigate this transformative journey, it's essential to recognize the obstacles that might hinder our progress. By pinpointing these challenges, we can craft effective strategies to overcome them and move closer to our aspirations. This stage is about self-awareness and honesty.";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newsMessage.push(element);
        elements.push(element);

        element = { role: "bot", content: "", effect: "delay", time: 1 };
        elements.push(element);

        content =
          "In this section, we'll take a moment to reflect on the roadblocks you've encountered or anticipate facing as you work towards your goals. I'll guide you through a series of questions designed to help you articulate these challenges. Your candid responses will be the foundation for the strategies we develop in the subsequent steps. Remember, every challenge is an opportunity in disguise – by addressing them head-on, you're already taking strides towards success.";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: false,
        };
        newsMessage.push(element);
        elements.push(element);

        element = { role: "bot", content: "", effect: "delay", time: 1 };
        elements.push(element);

        element = { role: "bot", content: "", effect: "set_step", step: 2 };
        elements.push(element);

        writeMessages(elements);
        setNewsMessages(newsMessage);
      } else if (step == 2) {
        if (currentGoalIdx >= goalData.length) {
          element = { role: "bot", content: "", effect: "set_step", step: 21 };
          elements.push(element);
          writeMessages(elements);
        } else {
          if (goalData.length > 1 && currentGoalIdx == 0) {
            content =
              "Let’s get started with the actions for your first goal.\n\n";
          } else {
            content = "Let’s review the actions for your goal.\n\n";
          }

          content +=
            "Goal: " +
            goalData[currentGoalIdx].goal +
            "\n\nActions you've planned:\n" +
            goalData[currentGoalIdx].action;
          element = {
            role: "bot",
            content: content,
            type: "text",
            effect: "type",
            speed: FAST_SPEED,
            showLogo: true,
          };
          newsMessage.push(element);
          elements.push(element);

          content =
            "Considering these actions, what challenges or obstacles can you foresee that might come in the way of achieving this goal? It could be external factors, personal habits, or even emotional responses. Take a moment to think about it.";
          element = {
            role: "bot",
            content: content,
            type: "text",
            effect: "type",
            speed: FAST_SPEED,
            showLogo: false,
          };
          newsMessage.push(element);
          elements.push(element);

          element = { role: "bot", content: "", effect: "set_step", step: 3 };
          elements.push(element);

          setCurrentChallenges([]);
          setCurrentChallengeIdx(0);

          writeMessages(elements);
          setNewsMessages(newsMessage);
        }
      } else if (step == 3) {
        if (currentChallenges.length == 0) {
          content =
            "What is a challenge you might encounter when trying to take one of these actions?";
        } else {
          content =
            "What is a challenge you might encounter when trying to take one of your action steps?";
        }
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newsMessage.push(element);
        elements.push(element);
        writeMessages(elements);
        setNewsMessages(newsMessage);
        setIsShowChat(true);
      } else if (step == 4) {
        let user_request = input;
        if (user_request && user_request != "") {
          let userMessage = { role: "user", content: input };
          newsMessage.push(userMessage);
          setMessages([...messages, userMessage]);

          setInput("");
          setTimeout(() => {
            scrollToBottom();
          }, 100);

          setCurrentChallenges([
            ...currentChallenges,
            { challenge: user_request, strategies: [] },
          ]);

          setIsProcessing(true);
          ackChallenge({
            session_id: session.id,
            goal_id: goalData[currentGoalIdx].id,
            challenge_entry: user_request,
          })
            .unwrap()
            .then(function (res) {
              setIsProcessing(false);
              console.log(res);
              if (res.summary_text) {
                let ackAndChallenges = extractAckAndChallenges(
                  res.summary_text
                );

                content = ackAndChallenges[0];
                element = {
                  role: "bot",
                  content: content,
                  type: "text",
                  effect: "type",
                  showLogo: true,
                };
                newsMessage.push(element);
                elements.push(element);

                element = {
                  role: "bot",
                  content: "",
                  effect: "delay",
                  time: 1,
                };
                elements.push(element);

                content = "Possible Challenge: " + ackAndChallenges[1];
                element = {
                  role: "bot",
                  content: content,
                  type: "text",
                  effect: "type",
                  showLogo: false,
                };
                newsMessage.push(element);
                elements.push(element);

                setPossibleChallenge(ackAndChallenges[1]);
                content =
                  "Would you like to add this to your list of challenges?";
                let choices = [
                  { value: "Yes", text: "Yes", step: 5 },
                  { value: "No", text: "No", step: 7 },
                ];
                element = {
                  role: "bot",
                  content: content,
                  type: "radiogroup",
                  choices: choices,
                  effect: "type",
                  showLogo: true,
                };
                newsMessage.push(element);
                elements.push(element);

                writeMessages(elements);
              }
            });
        }
      } else if (step == 5) {
        setIsProcessing(true);
        extractChallengeStatement({
          session_id: session.id,
          challenge: possibleChallenge,
        })
          .unwrap()
          .then(function (res) {
            console.log(res);
            if (res.summary_text) {
              let additionText = res.summary_text.trim();
              setAdditionChallenge(additionText);

              element = {
                role: "bot",
                content: "",
                effect: "set_step",
                step: 6,
              };
              elements.push(element);
              writeMessages(elements);
            }
          });
      } else if (step == 6) {
        let previousChallenges = "";
        if (currentChallenges && currentChallenges.length > 0) {
          for (let i = 0; i < currentChallenges.length; i++) {
            previousChallenges += currentChallenges[i].challenge + "\n";
          }
        }
        setCurrentChallenges([
          ...currentChallenges,
          { challenge: additionChallenge, strategies: [] },
        ]);
        ackChallenge({
          session_id: session.id,
          goal_id: goalData[currentGoalIdx].id,
          challenge_entry: additionChallenge,
          previous_challenges: previousChallenges,
        })
          .unwrap()
          .then(function (res) {
            setIsProcessing(false);
            if (res.summary_text) {
              let ackAndChallenges = extractAckAndChallenges(res.summary_text);
              content = ackAndChallenges[0];
              element = {
                role: "bot",
                content: content,
                type: "text",
                effect: "type",
                showLogo: true,
              };
              newsMessage.push(element);
              elements.push(element);

              element = { role: "bot", content: "", effect: "delay", time: 1 };
              elements.push(element);

              content = "Possible Challenge: " + ackAndChallenges[1];
              element = {
                role: "bot",
                content: content,
                type: "text",
                effect: "type",
                showLogo: false,
              };
              newsMessage.push(element);
              elements.push(element);

              setPossibleChallenge(ackAndChallenges[1]);
              content =
                "Would you like to add this to your list of challenges?";
              let choices = [
                { value: "Yes", text: "Yes", step: 5 },
                { value: "No", text: "No", step: 7 },
              ];
              element = {
                role: "bot",
                content: content,
                type: "radiogroup",
                choices: choices,
                effect: "type",
                showLogo: true,
              };
              newsMessage.push(element);
              elements.push(element);

              writeMessages(elements);
              setNewsMessages(newsMessage);
            }
          });
      } else if (step == 7) {
        content =
          "Are there any other challenges you would like to add to your list?";
        let choices = [
          { value: "Yes", text: "Yes", step: 3 },
          { value: "No", text: "No", step: 8 },
        ];
        element = {
          role: "bot",
          content: content,
          type: "radiogroup",
          choices: choices,
          effect: "type",
          showLogo: true,
        };
        newsMessage.push(element);
        elements.push(element);

        writeMessages(elements);
        setNewsMessages(newsMessage);

        /////////////section 2 //////////////////////
      } else if (step == 8) {
        content =
          "Great! Now that we've identified the potential challenges for your goal, it's time to strategize.\nTogether, we'll craft effective strategies to tackle each challenge, ensuring you're well-equipped to overcome any obstacles on your path to success. Ready to dive in?";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newsMessage.push(element);
        elements.push(element);
        element = { role: "bot", content: "", effect: "set_step", step: 9 };
        elements.push(element);

        writeMessages(elements);
        setNewsMessages(newsMessage);
      } else if (step == 9) {
        if (forceRender == currentForceRender) {
          element = {
            role: "bot",
            content: "I’m Ready!",
            type: "button",
            effect: "type",
            showLogo: true,
          };
          elements.push(element);
          writeMessages(elements);
          return;
        }
        setCurrentForceRender(!currentForceRender);
        content =
          "Excellent! Let's dive into strategy creation for each challenge you've identified. We'll brainstorm solutions, refine our ideas, and prioritize them to form a proactive game plan tailored to each obstacle. This way, you'll be equipped and ready to tackle challenges effectively as they arise.";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newsMessage.push(element);
        elements.push(element);

        element = { role: "bot", content: "", effect: "set_step", step: 10 };
        elements.push(element);

        writeMessages(elements);
        setNewsMessages(newsMessage);
      } else if (step == 10) {
        console.log("currentChallenges", currentChallenges);
        setIsUpdatedStrategy(true);
        if (currentChallengeIdx >= currentChallenges.length) {
          element = { role: "bot", content: "", effect: "set_step", step: 20 };
          elements.push(element);

          writeMessages(elements);
        } else {
          let currentChallenge = currentChallenges[currentChallengeIdx];
          content =
            "Here is a challenge you’ve identified for this goal: " +
            currentChallenge.challenge;
          element = {
            role: "bot",
            content: content,
            type: "text",
            effect: "type",
            showLogo: true,
          };
          newsMessage.push(element);
          elements.push(element);
          setIsProcessing(true);
          possibleStrategies({
            session_id: session.id,
            goal_id: goalData[currentGoalIdx].id,
            other_strategies: "",
            challenge: currentChallenge.challenge,
          })
            .unwrap()
            .then(function (res) {
              setIsProcessing(false);
              console.log(res);
              if (res.summary_text) {
                let strategies = extractStrategies(res.summary_text);
                setCurrentStrategies(strategies);

                content =
                  "Here are some strategies to help you overcome this challenge:\n" +
                  strategies.join("\n");
                element = {
                  role: "bot",
                  content: content,
                  type: "text",
                  effect: "type",
                  speed: FAST_SPEED,
                  showLogo: false,
                };
                newsMessage.push(element);
                elements.push(element);

                element = {
                  role: "bot",
                  content: "",
                  effect: "set_step",
                  step: 11,
                };
                elements.push(element);

                writeMessages(elements);
              }
            });
        }
      } else if (step == 11) {
        element = { role: "bot", content: "", effect: "delay", time: 1 };
        elements.push(element);

        content =
          "Please select the number(s) for any strategies you’d like to incorporate into your action plan. For multiple strategies, please use commas to separate your choices. For example, to select strategies one, three and five, you can type ‘1, 3, 5’ in the box below.";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: false,
        };
        newsMessage.push(element);
        elements.push(element);

        writeMessages(elements);

        setIsShowChat(true);
      } else if (step == 12) {
        let user_request = input;
        if (user_request && user_request != "") {
          let choiceNumbers = user_request.split(",");
          let strategiesList = [];
          let choiceNumList = [];
          for (let i = 0; i < choiceNumbers.length; i++) {
            let choiceNum = choiceNumbers[i];
            if (/^-?\d+$/.test(choiceNum)) {
              choiceNumList.push(Number(choiceNum));
            }
          }
          let sortedUniqueNumbers = [...new Set(choiceNumList)].sort(
            (a, b) => a - b
          );
          let finalNumbers = [];
          for (let i = 0; i < sortedUniqueNumbers.length; i++) {
            let choiceNum = sortedUniqueNumbers[i];
            if (currentStrategies.length >= choiceNum && choiceNum > 0) {
              finalNumbers.push(choiceNum);
              strategiesList.push(currentStrategies[choiceNum - 1]);
            }
          }

          let userMessage = { role: "user", content: finalNumbers.join(",") };
          newsMessage.push(userMessage);
          setMessages([...messages, userMessage]);

          setInput("");
          setTimeout(() => {
            scrollToBottom();
          }, 100);

          if (strategiesList.length == 0) {
            element = {
              role: "bot",
              content: "",
              effect: "set_step",
              step: 11,
            };
            elements.push(element);
            writeMessages(elements);
            return;
          }

          setSelectedStrategies(strategiesList);

          const modifiedChallenges = currentChallenges.map(
            (challenge, index) => {
              if (index == currentChallengeIdx) {
                return { ...challenge, strategies: strategiesList };
              }
              return challenge;
            }
          );

          setCurrentChallenges(modifiedChallenges);

          element = { role: "bot", content: "", effect: "set_step", step: 13 };
          elements.push(element);

          writeMessages(elements);
        }
      } else if (step == 13) {
        // content = "Here are your selected strategies.\n"
        // for (let i = 0; i < selectedStrategies.length; i++) {
        //     content += selectedStrategies[i] + "\n"
        // }
        // element = { role: 'bot', content: content, type: 'text', effect: 'put', showLogo: true }
        // newsMessage.push(element);
        // elements.push(element)

        content =
          "Would you like to add another strategy that is not on the list?";
        let choices = [
          { value: "Yes", text: "Yes", step: 14 },
          { value: "No", text: "No", step: 16 },
        ];
        element = {
          role: "bot",
          content: content,
          type: "radiogroup",
          choices: choices,
          effect: "type",
          showLogo: true,
        };
        newsMessage.push(element);
        elements.push(element);

        writeMessages(elements);
      } else if (step == 14) {
        content = "Please enter the strategy you want to add.";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newsMessage.push(element);
        elements.push(element);
        writeMessages(elements);
        setIsShowChat(true);
      } else if (step == 15) {
        setIsUpdatedStrategy(false);
        let user_request = input;
        if (user_request && user_request != "") {
          let userMessage = { role: "user", content: input };
          newsMessage.push(userMessage);
          setMessages([...messages, userMessage]);
          let finalIdx = selectedStrategies.length + 1;
          setSelectedStrategies([
            ...selectedStrategies,
            finalIdx + ". " + user_request,
          ]);

          const modifiedChallenges = currentChallenges.map(
            (challenge, index) => {
              if (index == currentChallengeIdx) {
                return { ...challenge, strategies: selectedStrategies };
              }
              return challenge;
            }
          );

          setCurrentChallenges(modifiedChallenges);

          setInput("");
          setTimeout(() => {
            scrollToBottom();
          }, 100);

          element = { role: "bot", content: "", effect: "set_step", step: 13 };
          elements.push(element);

          writeMessages(elements);
        }
      } else if (step == 16) {
        if (isUpdatedStrategy) {
          content = "Here is your list of strategies for this challenge:\n";
          for (let i = 0; i < selectedStrategies.length; i++) {
            content += selectedStrategies[i] + "\n";
          }
          element = {
            role: "bot",
            content: content,
            type: "text",
            effect: "put",
            showLogo: true,
          };
          newsMessage.push(element);
          elements.push(element);

          element = { role: "bot", content: "", effect: "delay", time: 1 };
          elements.push(element);
        }

        content = "Would you like to make any changes to these strategies?";
        let choices = [
          { value: "Changes needed", text: "Changes needed", step: 17 },
          { value: "No changes", text: "No changes", step: 19 },
        ];
        element = {
          role: "bot",
          content: content,
          type: "radiogroup",
          choices: choices,
          effect: "type",
          showLogo: !isUpdatedStrategy,
        };
        newsMessage.push(element);
        elements.push(element);

        writeMessages(elements);
        setNewsMessages(newsMessage);
      } else if (step == 17) {
        content = "What changes would you like to make to your strategies?";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newsMessage.push(element);
        elements.push(element);

        writeMessages(elements);
        setNewsMessages(newsMessage);
        setIsShowChat(true);
      } else if (step == 18) {
        let user_request = input;
        if (user_request && user_request != "") {
          let userMessage = { role: "user", content: input };
          newsMessage.push(userMessage);
          setMessages([...messages, userMessage]);

          setInput("");
          setTimeout(() => {
            scrollToBottom();
          }, 100);

          setIsProcessing(true);
          updateStrategies({
            session_id: session.id,
            user_entry: user_request,
            selected_strategies: selectedStrategies.join("\n"),
          })
            .unwrap()
            .then(function (res) {
              setIsProcessing(false);
              if (res.summary_text) {
                let strategies = extractStrategies(res.summary_text);
                content = "Here are your updated strategies.\n";
                for (let i = 0; i < strategies.length; i++) {
                  content += strategies[i] + "\n";
                }
                element = {
                  role: "bot",
                  content: content,
                  type: "text",
                  effect: "type",
                  speed: FAST_SPEED,
                  showLogo: true,
                };
                newsMessage.push(element);
                elements.push(element);

                const modifiedChallenges = currentChallenges.map(
                  (challenge, index) => {
                    if (index == currentChallengeIdx) {
                      return { ...challenge, strategies: strategies };
                    }
                    return challenge;
                  }
                );

                setCurrentChallenges(modifiedChallenges);

                setCurrentStrategies(strategies);
                element = {
                  role: "bot",
                  content: "",
                  effect: "set_step",
                  step: 13,
                };
                elements.push(element);

                writeMessages(elements);
                setNewsMessages(newsMessage);
                console.log(res);
              }
            });
        }
      } else if (step == 19) {
        if (currentChallengeIdx + 1 < currentChallenges.length) {
          if (forceRender == currentForceRender) {
            content =
              "Every challenge is a stepping stone to growth. Ready to tackle the next one?";
            element = {
              role: "bot",
              content: content,
              type: "text",
              effect: "type",
              showLogo: true,
            };
            elements.push(element);

            element = {
              role: "bot",
              content: "I’m Ready!",
              type: "button",
              effect: "type",
              showLogo: true,
            };
            elements.push(element);
            writeMessages(elements);
            return;
          }
          setCurrentForceRender(!currentForceRender);
        }
        setCurrentChallengeIdx(currentChallengeIdx + 1);
        element = { role: "bot", content: "", effect: "set_step", step: 10 };
        elements.push(element);

        writeMessages(elements);
      } else if (step == 20) {
        setCurrentGoalIdx(currentGoalIdx + 1);
        element = { role: "bot", content: "", effect: "set_step", step: 2 };
        elements.push(element);

        writeMessages(elements);
      } else if (step == 21) {
        content =
          "Session 3 is in the books! Your dedication to self-betterment shines brightly today.";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newsMessage.push(element);
        elements.push(element);

        element = { role: "bot", content: "", effect: "delay", time: 1 };
        elements.push(element);

        content =
          "Please review today’s session summary on the Life Coach main page and begin working towards your goals. Please be sure to track your progress prior to our next session where we will discuss your accountability plan.\nPlease press ‘Continue’ to exit this session.";
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newsMessage.push(element);
        elements.push(element);

        writeMessages(elements);
        setNewsMessages(newsMessage);

        handleFinishSession();
      }

      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }, [step, forceRender]);

  const handleSend = async () => {
    setStep(step + 1);
    setIsShowChat(false);
  };

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ block: "end" });
  };

  const handleContinue = () => {
    setIsContinue(false);
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const selectChoice = async (choice) => {
    setMessages((prevList) => {
      if (prevList.length === 0) return [choice]; // handle case where list is empty

      const newList = [...prevList]; // create a shallow copy
      newList[newList.length - 1].selected_choice = choice.value; // change the last item
      return newList;
    });
    setCurrentChoice(choice);
    setStep(choice.step);
    sendSessionMessage({ session_id: session.id, choice: choice.value })
      .unwrap()
      .then(function (res) {
        console.log(res);
        setIsProcessing(false);
      });
  };

  const handleFinishSession = () => {
    setSessionEnded(true);
    generateSessionSummary({ session_id: session.id })
      .unwrap()
      .then(function (res) {
        console.log(res);
      });
    scrollToBottom();
  };

  const handleFinish = () => {
    window.location.href = "/life-coach";
  };

  const handleChatContinue = () => {
    setForceRender(!forceRender);
    const newMessages = messages.map((message) => {
      if (message.type == "button") {
        return { ...message, role: "user", type: "text" };
      }
      return message;
    });

    setMessages(newMessages);
  };

  return (
    <div className="life-coach-session-component">
      {isContinue == true ? (
        <>
          <SessionWelcomeBackComponent />
          <Row className="mt-4">
            <Col className="text-center">
              <Button
                className="btn-yellow mt-3"
                size="lg"
                onClick={() => handleContinue()}
              >
                CONTINUE
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {userSessionHistory && (
            <SessionMessageListComponent
              isShowChat={isTyping}
              messages={messages}
              userLogoName={userLogoName}
              selectChoice={selectChoice}
              handleContinue={handleChatContinue}
              forceRender={forceRender}
            />
          )}
          {sessionEnded == false && isProcessing == true ? (
            <SessionBotProcessingComponent />
          ) : (
            isShowChat && (
              <SessionChatInputComponent
                chatInputRef={chatInputRef}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onSend={handleSend}
              />
            )
          )}
          {sessionFinished == true && (
            <h6
              style={{
                textAlign: "center",
                color: "white",
                marginTop: "2rem",
                marginBottom: "4rem",
              }}
            >
              this session was already taken
            </h6>
          )}

          {isTyping ? (
            sessionEnded == true ? (
              <Row className="mt-4">
                <Col className="text-center">
                  <Button
                    className="btn-yellow mt-3"
                    ref={closeBtnRef}
                    size="lg"
                    onClick={() => handleFinish()}
                  >
                    CONTINUE
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row className="mt-4">
                <Col className="text-center">
                  <Button
                    className="btn-yellow mt-3"
                    ref={closeBtnRef}
                    size="lg"
                    onClick={() => handlePopupClose()}
                  >
                    PAUSE
                  </Button>
                </Col>
              </Row>
            )
          ) : (
            <></>
          )}
        </>
      )}
      <span ref={scrollRef}></span>
    </div>
  );
};

export default LifeCoachCustomSession3Component;
