import React, { useEffect, useState } from 'react';
import './css/signup-step.css'
const SignupStepComponent = ({ step }) => {

    const generateClassName = (order) => {
        let className = ""
        if (order <= step) {
            className = ""
            if (order == step) {
                className += " current";
            }else{
                className += " done"
            }
        } else {
            className = "disabled"
        }
        if(order == 0){
            className += " first"
        }

        return className;

    }


    return (
        <div className='signup-step-container'>
            <div className="steps clearfix">
                <ul role="tablist">
                    <li role="tab" className={generateClassName(0)} aria-disabled="true" aria-selected={step == 0 ? true : false}>
                        <a id="steps-uid-0-t-0">
                            <span className="current-info audible">current step: </span><span className="step">1</span> Register
                        </a>
                    </li>
                    <li role="tab" className={generateClassName(1)} aria-disabled="true" aria-selected={step == 1 ? true : false}>
                        <a id="steps-uid-0-t-1"><span className="step">2</span> Verify Email</a>
                    </li>
                    <li role="tab" className={generateClassName(2)} aria-disabled="true" aria-selected={step == 2 ? true : false}>
                        <a id="steps-uid-0-t-2"><span className="step">3</span> Review Policies</a>
                    </li>
                    <li role="tab" className={generateClassName(3)} aria-disabled="true" aria-selected={step == 3 ? true : false}>
                        <a id="steps-uid-0-t-3"><span className="step">4</span> Payment</a>
                    </li>
                    <li role="tab" className={generateClassName(4)} aria-disabled="true" aria-selected={step == 4 ? true : false}>
                        <a id="steps-uid-0-t-4"><span className="step">5</span> Notifications</a>
                    </li>
                </ul>
            </div>
        </div>

    )

}

export default SignupStepComponent;