import profile from "../../assets/images/menu/profile-icon.svg";
import feedback from "../../assets/images/menu/contact-icon.svg";
import help from "../../assets/images/menu/help-icon.svg";
import term from "../../assets/images/menu/journal-icon.svg";
import privacy from "../../assets/images/menu/goals-icon.svg";

var menuUserList = [
    {
        url : '/profile',
        title: 'Profile',
        svg : profile
    },
    {
        url : '/dashboard/privacy',
        title: 'Privacy Policy',
        svg : privacy
    },
    {
        url : '/dashboard/terms-of-service',
        title: 'Terms of Service',
        svg : term
    },
    {
        url : 'https://mosaiclifetech.com/feedback',
        title: 'Feedback',
        svg : feedback
    },
    {
        url : 'https://mosaiclifetech.com/support',
        title: 'Support',
        svg : help
    }
]

export default menuUserList;
