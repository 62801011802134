import React from 'react'
import { useDispatch, connect } from "react-redux"
import { useNavigate } from 'react-router-dom'

import { useLogoutMutation } from "./../../features/auth/slices/authApiSlice"
import { LOG_OUT } from '../../redux/constants/actionTypes';
import avatarPng from './../../assets/images/avatar.png'

import Container from 'react-bootstrap/Container';
import { Dropdown } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
// import MainFooter from '../../components/main/MainFooter';

const mapStateToProps = (state) => ({
  user: state.auth.user
});

const SetupLayout = ({ children, user }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation()

  const handleLogOut = async (e) => {
    try {
      const responseData = await logout().unwrap()
    } catch (err) {
      console.log("err", err)
    }
    dispatch({ type: LOG_OUT })
    navigate('/', { replace: true });
}

  return (
    <>
      <div className="quize_dashboard">
      <div className="topbar" style={{left: 0}}>
            {/* <!-- Navbar --> */}
            <nav className="navbar-custom">
              <ul className="list-unstyled topbar-nav mb-0 d-flex align-items-center">
                <li>
                </li>
              </ul>
              <Dropdown>
                <Dropdown.Toggle style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                  <span className="mr-3 hidden-sm text-white" style={{ fontSize: '1.5rem' }}>{user ? user.first_name + " " + user.last_name : ''}  </span> &nbsp;&nbsp;
                  <Image src={avatarPng} width='30' alt="profile-user" roundedCircle />
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ textAlign: 'center' }}>
                  <Dropdown.Item style={{ padding: '10px 20px', width: 'auto' }} onClick={() => handleLogOut()}>Log out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

            </nav>
          </div>

          {/* <!-- Page Content--> */}
          <div className="page-content">
            <Container>
              {children}
            </Container>
          </div>
      </div>

    </>

  )

}
export default connect(mapStateToProps)(SetupLayout);
