import React, { lazy } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Layouts from './layouts'
import { connect } from 'react-redux'
import RouterCombiner from "./routerCombiner";
import './assets/font/stylesheet.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'survey-core/defaultV2.min.css';
import 'animate.css';

const PageRoutes = [
  {
    path: "/",
    Component: lazy(() => import('./features/landing/pages/Landing')),
    Layout: Layouts.public
  },
  {
    path: '/login',
    Component: lazy(() => import('./features/auth/pages/Login')),
    Layout: Layouts.auth,
    exact: true,
  },
  {
    path: '/register',
    Component: lazy(() => import('./features/auth/pages/Register')),
    Layout: Layouts.auth,
    exact: true,
  },
  {
    path: '/google-oauth/callback/:mode',
    Component: lazy(() => import('./features/auth/pages/GoogleOAuthCallback')),
    Layout: Layouts.auth,
    exact: true,
  },
  {
    path: '/facebook-oauth/callback/:mode',
    Component: lazy(() => import('./features/auth/pages/FacebookOAuthCallback')),
    Layout: Layouts.auth,
    exact: true,
  },
  {
    path: '/verification',
    Component: lazy(() => import('./features/auth/pages/EmailVerification')),
    Layout: Layouts.auth,
    exact: true,
  },
  {
    path: '/activate/:uidb64/:token',
    Component: lazy(() => import('./features/auth/pages/Activate')),
    Layout: Layouts.auth,
    exact: true,
  },
  {
    path: '/email-forgot-password',
    Component: lazy(() => import('./features/auth/pages/EnterYourEmail')),
    Layout: Layouts.verify,
    exact: true,
  },
  {
    path: '/forgot-password',
    Component: lazy(() => import('./features/auth/pages/ForgotPassword')),
    Layout: Layouts.auth,
    exact: true,
  },
  {
    path: '/reset-password/:uidb64/:token',
    Component: lazy(() => import('./features/auth/pages/ResetPassword')),
    Layout: Layouts.auth,
    exact: true,
  },
  {
    path: '/accept-terms',
    Component: lazy(() => import('./features/termsAndPrivacy/pages/AcceptTerms')),
    Private: true,
    Layout: Layouts.auth,
    exact: true,
  },
  {
    path: '/dashboard/privacy',
    Component: lazy(() => import('./features/termsAndPrivacy/pages/PrivacyPolicy')),
    Private: false,
    Layout: Layouts.main,
    exact: true,
    Public: true
  },
  {
    path: '/dashboard/terms-of-service',
    Component: lazy(() => import('./features/termsAndPrivacy/pages/TermsOfService')),
    Private: false,
    Layout: Layouts.main,
    exact: true,
    Public: true
  },
  {
    path: '/privacy',
    Component: lazy(() => import('./features/termsAndPrivacy/pages/PrivacyPolicy')),
    Private: false,
    Layout: Layouts.terms,
    exact: true,
    Public: true
  },
  {
    path: '/terms-of-service',
    Component: lazy(() => import('./features/termsAndPrivacy/pages/TermsOfService')),
    Private: false,
    Layout: Layouts.terms,
    exact: true,
    Public: true
  },
  {
    path: '/privacy-mosaic',
    Component: lazy(() => import('./features/termsAndPrivacy/pages/PrivacyPolicy')),
    Private: false,
    Layout: Layouts.terms,
    exact: true,
    Public: true
  },
  {
    path: '/terms-of-service-mosaic',
    Component: lazy(() => import('./features/termsAndPrivacy/pages/TermsOfService')),
    Private: false,
    Layout: Layouts.terms,
    exact: true,
    Public: true
  },
  {
    path: '/cookie',
    Component: lazy(() => import('./features/termsAndPrivacy/pages/CookiePolicy')),
    Private: false,
    Layout: Layouts.terms,
    exact: true,
    Public: true
  },
  {
    path: '/pricing',
    Component: lazy(() => import('./features/stripe/pages/Pricing')),
    Private: true,
    Layout: Layouts.auth,
    exact: true,
  },
  {
    path: '/success',
    Component: lazy(() => import('./features/stripe/pages/Success')),
    Private: true,
    Layout: Layouts.setup,
    exact: true,
  },
  {
    path: '/all-set',
    Component: lazy(() => import('./features/auth/pages/AllSet')),
    Layout: Layouts.auth,
    exact: true,
    Private: true,
  },
  {
    path: "/dashboard",
    Component: lazy(() => import('./features/dashboard/pages/Dashboard')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/my-assessments",
    Component: lazy(() => import('./features/assessments/pages/Assessment')),
    Private: true,
    Layout: Layouts.main,
  },
  {
    path: "/my-action-plans",
    Component: lazy(() => import('./features/plans/pages/MyActionPlan')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/assessment-report",
    Component: lazy(() => import('./features/plans/pages/AssessmentReport')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/action-plan-checklist",
    Component: lazy(() => import('./features/plans/pages/ActionPlanChecklist')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/assessment/new/:categoryId",
    Component: lazy(() => import('./features/assessments/pages/AssessmentQuiz')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/assessment/result/:resultId",
    Component: lazy(() => import('./features/assessments/pages/Assessment')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/profile",
    Component: lazy(() => import('./features/user/pages/Profile')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/manage-account",
    Component: lazy(() => import('./features/user/pages/ManageAccount')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/rewards",
    Component: lazy(() => import('./features/rewards/pages/UserRewards')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/account-deleted",
    Component: lazy(() => import('./features/user/pages/AccountDeleted')),
    Layout: Layouts.blank
  },
  {
    path: "/my-documents",
    Component: lazy(() => import('./features/myuploadedfiles/pages/MyUploadedFiles')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/404",
    Component: lazy(() => import('./features/error/pages/Page404Error')),
    Private: false,
    Layout: Layouts.auth,
  },
  // {
  //   path: '/upgrade-plan',
  //   Component: lazy(() => import('./features/stripe/pages/UpgradePlan')),
  //   Private: true,
  //   Layout: Layouts.main,
  //   exact: true,
  // },
  {
    path: "/share-progress/:userId/:token/:date",
    Component: lazy(() => import('./features/plans/pages/ProgressShare')),
    Private: false,
    Layout: Layouts.share,
    Public: true
  },
  {
    path: "/releases",
    Component: lazy(() => import('./features/releases/pages/Releases')),
    Private: false,
    Layout: Layouts.share,
    Public: true
  },
  {
    path: "/my-journal",
    Component: lazy(() => import('./features/journals/pages/MyJournal')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/today-journal/:templateId",
    Component: lazy(() => import('./features/journals/pages/TodayJournal')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/journal-history",
    Component: lazy(() => import('./features/journals/pages/JournalHistory')),
    Private: true,
    Layout: Layouts.main
  },
  // {
  //   path: "/journal/:templateId/:date",
  //   Component: lazy(() => import('./features/journals/pages/Journal')),
  //   Private: true,
  //   Layout: Layouts.main
  // },
  {
    path: "/journal",
    Component: lazy(() => import('./features/journals/pages/JournalV2')),
    Private: true,
    Layout: Layouts.main
  },
  // {
  //   path: "/journal/view/:historyId",
  //   Component: lazy(() => import('./features/journals/pages/JournalHistory')),
  //   Private: true,
  //   Layout: Layouts.main
  // },
  // {
  //   path: "/resources/:type",
  //   Component: lazy(() => import('./features/resources/pages/Resouces')),
  //   Private: true,
  //   Layout: Layouts.main
  // },
  {
    path: "/resources",
    Component: lazy(() => import('./features/resources/pages/ResourceV2')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/resources/affirmation/:affirmationID",
    Component: lazy(() => import('./features/resources/pages/AffirmationDetail')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/resources/video/:videoID",
    Component: lazy(() => import('./features/resources/pages/VideoDetail')),
    Private: true,
    Layout: Layouts.main
  },
  // {
  //   path: "/daily-affirmations",
  //   Component: lazy(() => import('./features/resources/pages/DailyAffirmations')),
  //   Private: true,
  //   Layout: Layouts.main
  // },
  {
    path: "/resources/tutorial/:tutorialID",
    Component: lazy(() => import('./features/resources/pages/TutorialDetail')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/tryitfree",
    Component: lazy(() => import('./features/LeadMagnet/pages/LeadMagnet')),
    Private: false,
    Layout: Layouts.leadMagnet,
    Public: true
  },
  {
    path: "/life-coach",
    Component: lazy(() => import('./features/LifeCoach/pages/LifeCoach')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/life-coach-summary-history",
    Component: lazy(() => import('./features/LifeCoach/pages/LifeCoachSummaryHistory')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/goals",
    Component: lazy(() => import('./features/goals/pages/Goals')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/contact",
    Component: lazy(() => import('./features/contact/pages/Contact')),
    Private: true,
    Layout: Layouts.main
  },
  {
    path: "/help",
    Component: lazy(() => import('./features/help/pages/Help')),
    Private: true,
    Layout: Layouts.main
  }
];

const mapStateToProps = (state) => ({
  auth: state.auth,
})
const App = ({ auth }) => {
  return (
    <Router>
      <RouterCombiner
        routes={PageRoutes}
        auth={auth}
      />
    </Router>
  );
};

export default connect(mapStateToProps)(App)
