import React, { useState, useEffect } from 'react';
import './../css/session-welcome-back-component.css'

const SessionWelcomeBackComponent = () => {
    return (
        <div className='session-welcome-back-component'>
            <h5>Welcome Back!</h5>
            <br></br>
            <p>Life can be full of interruptions, but don't worry! We've saved all your progress. You can pick up right where you left off and continue on your transformative journey with Mosaic Life.</p>
            <br></br>
            <p>Please press 'Continue' when you're ready to dive back in. We're here to guide and support you every step of the way!</p>
        </div>

    )

}

export default SessionWelcomeBackComponent