import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCtVa_x-Bze44VuKYSm0ziC3Yemg_3-18Y",
  authDomain: "mosaic-life-coach-a70f5.firebaseapp.com",
  projectId: "mosaic-life-coach-a70f5",
  storageBucket: "mosaic-life-coach-a70f5.appspot.com",
  messagingSenderId: "1008269877604",
  appId: "1:1008269877604:web:ced4053eb3594557f0da71",
  measurementId: "G-WTMZ6YFNY8"
};

let app;
let messaging;

// Initialize Firebase
const { pathname } = window.location;
if (pathname !== '/privacy' && pathname !== '/terms-of-service') {
  app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
} else {
    console.log('Firebase not initialized for privacy and terms of service pages.');
}

export { app, messaging };

export const generateToken = async ({
    setFCMToken,
    removeFCMToken,
}) => {
    if (messaging) {
        const permission = await Notification.requestPermission();

        if  (permission == 'granted') {
            console.log('Notification permission granted.');
            try {
                const token = await getToken(messaging, { vapidKey: "BNcWEa2DHwbkz4ZkwQ-xUPluxSC3hwlkaAWWZYyBw5JqjDkkKQ_s-CVPGRUjtFn7lF9D2AI4ca81EEPdl7Jhlac" });
                console.log('Token:', token);

                const storedToken = localStorage.getItem('firebaseToken');

                if (!storedToken) {
                    // If not, save the new token to localStorage
                    localStorage.setItem('firebaseToken', token);
                    console.log('Token saved to localStorage');
                    setFCMToken({fcm_token: token});
                } else if (storedToken !== token) {
                    // If stored token is different from new token, update it
                    localStorage.setItem('firebaseToken', token);
                    console.log('Token updated in localStorage');
                    removeFCMToken({ token: decodeURIComponent(storedToken)});
                    setFCMToken({fcm_token: token});
                }
            } catch (error) {
                console.log('An error occurred while retrieving token. ', error);
            }
        } else {
            console.log('Unable to get permission to notify.');
        }
    }

};
