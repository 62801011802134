import React from 'react';
import SessionTagComponent from './SessionTagComponent';
import './../css/session-tag-list-component.css'

const SessionTagListComponent = ({ choices, selectChoice, selectedChoice }) => {
    return (
        <div className="session-tag-list-component">
            {choices.map((choice, index) => (
                <SessionTagComponent key={index} choice={choice} selectChoice={selectChoice} selectedChoice={selectedChoice} />
            ))}
            
        </div>

    )

}

export default SessionTagListComponent;