import { apiSlice } from "./../../../apiSlice";

export const rewardsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLatestBadge: builder.query({
            query: () => 'rewards/user-latest-badge/',
            keepUnusedDataFor: 5,
        }),
        getUserBadges: builder.mutation({
            query: () =>({
                url: 'rewards/user-badges/',
                method: 'POST'
            })
        }),
        getUserRewards: builder.query({
            query: () => 'rewards/user-rewards/',
            keepUnusedDataFor: 5,
        }),
        getUserBadgeAlerts: builder.mutation({
            query: () =>({
                url: 'rewards/get-badge-alerts/',
                method: 'GET'
            })
        }),
        ackUserBadgeAlerts: builder.mutation({
            query: () =>({
                url: 'rewards/ack-badge-alerts/',
                method: 'POST'
            })
        }),
        
    })
})

export const {
    useGetLatestBadgeQuery,
    useGetUserBadgesMutation,
    useGetUserRewardsQuery,
    useGetUserBadgeAlertsMutation,
    useAckUserBadgeAlertsMutation
} = rewardsApiSlice 