import React, { useEffect, useRef } from 'react';
import botPng from './../../../assets/images/bot.png'
import './../css/session-ended-component.css'

const SessionEndedComponent = ({ order }) => {
    const ref = useRef(null);
    useEffect(() => {
        const timer = setTimeout(() => {
            ref.current?.scrollIntoView({ behavior: 'smooth' })
        }, 1000);

        return () => clearTimeout(timer);
    }, []); 

    return (
        <div className={`session-ended-component session-ended-component-bot`}>
            <div className={`message bot`}>
                <img className='bot-logo' src={botPng}></img>
                <div className={`message-content bot`} >
                    <ol className='mt-4'>
                        <li>
                            Review Your Session Summary: Press “continue” and head over to the main life coach page to review a summary of what we've discussed today.
                        </li>
                        <li>
                            Complete Your Assessments: Your next step is to navigate to the 'My Assessments' page to complete assessments tailored to your Mosaic Life Areas. This will help us fine-tune your coaching experience in Session 2.
                        </li>
                        <li>
                            Generate an Assessment Report: After completing the assessments, you will now be able to generate an Assessment Report. This gives you a comprehensive view of your current state across different life areas.
                        </li>
                    </ol>
                    <p className='mt-4'>Once you've completed these steps, Session {order + 1} will be unlocked and available for you tomorrow. Keep up the great work, and we look forward to supporting you on your journey towards well-being!</p>
                    <p className='mt-4'>By pressing 'Continue', you'll receive a summary of today's session and the next steps to keep your momentum going. Let's celebrate this milestone and look forward to the exciting journey ahead!
                    </p>
                </div>
            </div>

        </div>

    )

}

export default SessionEndedComponent;