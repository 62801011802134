import { apiSlice } from "./../../../apiSlice";

export const lifeCoachApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSessions: builder.mutation({
            query: () => ({
                url: `life-coaches/sessions/`,
                method: 'GET'
            })
        }),
        getUserSession: builder.mutation({
            query: (params) => ({
                url: `life-coaches/user-session/?session_id=${params.session_id}`,
                method: 'GET'
            })
        }),
        sendSessionMessage: builder.mutation({
            query: (params) => ({
                url: 'life-coaches/user-session/',
                method: 'POST',
                body: { ...params }
            })
        }),
        getUserSessionSummaryStatus: builder.mutation({
            query: () => ({
                url: `life-coaches/summary-status/`,
                method: 'GET'
            })
        }),
        generateSessionSummary: builder.mutation({
            query: (params) => ({
                url: `life-coaches/summary-status/`,
                method: 'POST',
                body: { ...params }
            })
        }),
        getSessionSummaries: builder.mutation({
            query: (params) => ({
                url: `life-coaches/summaries/?session_id=${params.session_id}&date=${params.date}&page_size=${params.page_size}&page=${params.page}`,
                method: 'GET'
            })
        }),
        getUserAnalyzedGoals: builder.mutation({
            query: () => ({
                url: `life-coaches/user-goal/`,
                method: 'GET'
            })
        }),
        sendCustomSessionMessage: builder.mutation({
            query: (params) => ({
                url: `life-coaches/user-custom-session/${params.session_id}/`,
                method: 'POST',
                body: { ...params }
            })
        }),

        getUpdateGoal: builder.mutation({
            query: (params) => ({
                url: "life-coaches/cs_updateGoal/",
                method: 'POST',
                body: { ...params }
            })
        }),
        deleteGoal: builder.mutation({
            query: (params) => ({
                url: "life-coaches/cs_updateGoal/",
                method: 'DELETE',
                body: { ...params }
            })
        }),
        getSmartCriteriaEvaluation: builder.mutation({
            query: (params) => ({
                url: "life-coaches/cs_smart_criteria_evaluation/",
                method: 'POST',
                body: { ...params }
            })
        }),
        getIntroductionValueAlignment: builder.mutation({
            query: (params) => ({
                url: "life-coaches/cs_introduction_value_alignment/",
                method: 'POST',
                body: { ...params }
            })
        }),

        updateGoalAction: builder.mutation({
            query: (params) => ({
                url: "life-coaches/cs_updateActionSteps/",
                method: 'POST',
                body: { ...params }
            })
        }),        
        ackChallenge: builder.mutation({
            query: (params) => ({
                url: "life-coaches/cs_ack_challenge/",
                method: 'POST',
                body: { ...params }
            })
        }),
        possibleStrategies: builder.mutation({
            query: (params) => ({
                url: "life-coaches/cs_possible_strategies/",
                method: 'POST',
                body: { ...params }
            })
        }),        
        updateStrategies: builder.mutation({
            query: (params) => ({
                url: "life-coaches/cs_update_strategies/",
                method: 'POST',
                body: { ...params }
            })
        }),        
        extractChallengeStatement: builder.mutation({
            query: (params) => ({
                url: "life-coaches/cs_extract_challenge_statement/",
                method: 'POST',
                body: { ...params }
            })
        }),        
        getLastSessionOrder: builder.mutation({
            query: () => ({
                url: `life-coaches/last-session-order/`,
                method: 'GET'
            })
        }),
        assistantDialogPrompt: builder.mutation({
            query: (params) => ({
                url: "life-coaches/cs_assistant_dialog/",
                method: 'POST',
                body: { ...params }
            })
        }),        
        assistantPkb: builder.mutation({
            query: (params) => ({
                url: "life-coaches/assistant_pkb/",
                method: 'POST',
                body: { ...params }
            })
        }),        

    })
})

export const {
    useGetSessionsMutation,
    useGetUserSessionMutation,
    useSendSessionMessageMutation,
    useGetUserSessionSummaryStatusMutation,
    useGetSessionSummariesMutation,
    useGenerateSessionSummaryMutation,
    useGetUserAnalyzedGoalsMutation,
    useSendCustomSessionMessageMutation,
    useGetUpdateGoalMutation,
    useGetSmartCriteriaEvaluationMutation,
    useGetIntroductionValueAlignmentMutation,
    useDeleteGoalMutation,
    useUpdateGoalActionMutation,
    useAckChallengeMutation,
    usePossibleStrategiesMutation,
    useUpdateStrategiesMutation,
    useExtractChallengeStatementMutation,
    useGetLastSessionOrderMutation,
    useAssistantDialogPromptMutation,
    useAssistantPkbMutation
} = lifeCoachApiSlice 