import React from 'react'

const TermsLayout = ({ children }) => {

    return (
        <>
            <div className="page-content" style={{backgroundColor:'#d7a03e'}}>
                {children}
            </div>
        </>

    )

}
export default TermsLayout;
