import assessment from "../../assets/images/menu/assessment-icon.svg";
import dashboard from "../../assets/images/menu/dashboard-icon.svg";
import goals from "../../assets/images/menu/goals-icon.svg";
import journal from "../../assets/images/menu/journal-icon.svg";
import resources from "../../assets/images/menu/resources-icon.svg";

var menuList = [
    {
        url : '/dashboard',
        title: 'Dashboard',
        svg : dashboard
    },
    {
        url : '/my-assessments',
        startwith:'/assessment/',
        includeList:['/assessment-history', '/assessment-summary'],
        title: 'Assessments',
        svg : assessment
    },
    {
        url : '/goals',
        title: 'Goals',
        svg : goals
    },
    {
        url : '/journal',
        startwith:'/journal/',
        includeList:['/journal-history'],
        title: 'Journal',
        svg : journal
    },
    {
        url : '/resources',
        startwith:'/resources/',
        title: 'Resources',
        includeList:['/daily-affirmations'],
        svg : resources
    }
]

export default menuList;
