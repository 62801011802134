import { 
  SET_USER_INFORMATION_STATE,
  SET_USER_CHAT_HISTORY,
  UPDATE_USER_CHAT_HISTORY,
  UPDATE_CHAT_STATUS,
  UPDATE_CHAT_OPEN,
  UPDATE_SUGGEST_QUESTIONS,
  UPDATE_CREATE_GOALS,
  RESET_SUGGESTIONS,
} from './../constants/actionTypes';

const initialState = {
  userInformationState: {},
  chatHistory: [],
  suggestQuestions: [
    "Do you have any specific interests or hobbies that you enjoy?",
    "Are there any social groups or clubs in your area that you might be interested in?",
    "How do you usually spend your free time?",
    "Have you tried reaching out to any acquaintances or colleagues recently?"
  ],
  createGoals: [{
    "create_goals": true,
    "action": "Spend 10 minutes each day researching local clubs or groups and attend at least one meeting within the next 21 days",
    "goal": "Form new friendships",
    "area_name": "Social Well-being"
  }],
  isWaiting: false,
  isChatOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFORMATION_STATE:
      return {
        ...state,
        userInformationState: action.payload.userInformationState,
      };

    case SET_USER_CHAT_HISTORY:
      return {
        ...state,
        chatHistory: action.payload.chat_history,
      };

    case UPDATE_USER_CHAT_HISTORY:
      return {
        ...state,
        chatHistory: [...state.chatHistory, action.payload.message],
      };

    case UPDATE_CHAT_STATUS:
      return {
        ...state,
        isWaiting: action.payload.waitingStatus,
      };

    case UPDATE_CHAT_OPEN:
      return {
        ...state,
        isChatOpen: action.payload.openStatus,
      };

    case UPDATE_SUGGEST_QUESTIONS:
      return {
        ...state,
        suggestQuestions: action.payload?.suggest_questions || [],
      };
  
    case UPDATE_CREATE_GOALS:
      if (action.payload.action_button?.create_goals) {
        return {
          ...state,
          createGoals: [action.payload.action_button] || [],
        };
      }

      return state;

    case RESET_SUGGESTIONS:
      return {
        ...state,
        createGoals: [],
        suggestQuestions: [],
      };
      

    default:
      return state;
  }
};
