import { apiSlice } from "./../../../apiSlice";

export const releasesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReleases: builder.query({
            query: () => 'version/releases/',
            keepUnusedDataFor: 5,
        }),
        getLatestRelease: builder.query({
            query: () => 'version/latest-release/',
            keepUnusedDataFor: 5,
        }),
    })
})

export const {
    useGetReleasesQuery,
    useGetLatestReleaseQuery
} = releasesApiSlice 