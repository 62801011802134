import React from 'react';
import SessionMessageComponent from './SessionMessageComponent';
import './../css/session-message-list-component.css'

const SessionMessageListComponent = ({ isShowChat, messages, userLogoName, selectChoice, handleContinue, forceRender }) => {
    return (
        <div className="session-message-list-component">
            {messages && messages.map((message, index) => (
                <SessionMessageComponent isShowChat={isShowChat} key={index} userLogoName={userLogoName} message={message} showLogo={message.role != 'bot' || message.showLogo == true ? true : false} selectChoice={selectChoice} handleContinue={handleContinue} forceRender={forceRender} />
            ))}
        </div>

    )

}

export default SessionMessageListComponent;