import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "../apiSlice"
import authReducer from './reducers/auth'
import quizReducer from './reducers/quiz'
import themeReducer from './reducers/theme'
import sessionReducer from './reducers/session'
import coachReducer from './reducers/coach'
import notificationReducer from './reducers/notification'

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        quiz: quizReducer,
        theme: themeReducer,
        session: sessionReducer,
        coach: coachReducer,
        notification: notificationReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
});
