import * as React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function Loading() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', verticalAlign: 'middle', margin: 'auto' }}>
      <div style={{textAlign: 'center'}}>
        <Spinner animation="border" role="status" style={{ marginTop: '25%', height: '100px', width: '100px', borderLeftColor:'#11393e', borderTopColor:'#11393e', borderBottomColor:'#11393e' }}>
        </Spinner>
        <h6 style={{marginTop:'10%'}}>Just a moment, we're loading your content...</h6>
      </div>
    </div>
  );
}