import React, { useEffect, useState } from "react";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch, connect, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";

import menuList from "./menuList";
import menuUserList from "./menuUserList";
import Analytics from "../../features/assessments/components/Analytics";
import BadgeAlertPopup from "../../components/popups/BadgeAlertPopup";
import LifeCoachSessionPopup from "../../features/LifeCoach/components/LifeCoachSessionPopup";
import logoWhiteSvg from "./../../assets/images/logo-white.svg";
import closeSvg from "./../../assets/images/white-close.svg";
import logoutIcon from '../../assets/images/menu/logout-icon.svg'
import { useLogoutMutation } from "./../../features/auth/slices/authApiSlice";
import {
  SET_OPEN,
  SET_CLOSE,
  LOG_OUT,
  SET_MOBILE_MODE,
  SET_DESKTOP_MODE,
} from "../../redux/constants/actionTypes";
import {
  useGetUserBadgeAlertsMutation,
  useAckUserBadgeAlertsMutation,
} from "../../features/rewards/slices/rewardsApiSlice";

import "./../css/main.css";
import "react-toastify/dist/ReactToastify.css";
import { generateToken, messaging } from "../../features/notification/firebase";
import { onMessage } from "firebase/messaging";
import { useRemoveFCMTokenMutation, useSetFCMTokenMutation } from "../../features/user/slices/userApiSlice";

const MainLayout = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  
  const openAiStatusCheckTime = useSelector(
    (state) => state.auth.openAiStatusCheckTime
  );

  const open = useSelector((state) => state.theme.open);
  const isMobile = useSelector((state) => state.theme.isMobile);
  const sessions = useSelector((state) => state.session.sessions);
  const lastSessionOrder = useSelector(
    (state) => state.session.lastSessionOrder
  );
  const goalCount = useSelector((state) => state.session.goalCount);
  const isWatchedLifeCoachTutorial = useSelector(
    (state) => state.auth.isWatchedLifeCoachTutorial
  );
  const isActionPlanAccepted = useSelector(
    (state) => state.session.isActionPlanAccepted
  );
  const [showOpenaiAlert, setShowOpenaiAlert] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const session_order = queryParameters.get("session_order");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [isShowBadgeAlert, setIsShowBadgeAlert] = useState(false);
  const [badgeAlerts, setBadgeAlerts] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [showSessionPopup, setShowSessionPopup] = useState(false);

  const [getUserBadgeAlerts] = useGetUserBadgeAlertsMutation();
  const [ackUserBadgeAlerts] = useAckUserBadgeAlertsMutation();
  const [setFCMToken] = useSetFCMTokenMutation({
    onError: (error) => {
      console.error(error);
    },
  }); 
  const [removeFCMToken] = useRemoveFCMTokenMutation({
    onError: (error) => {
      console.error(error);
    },
  }); 

  const fetchStatusData = async () => {
    try {
      const response = await fetch(
        "https://status.openai.com/api/v2/status.json"
      );
      if (response.ok) {
        const data = await response.json();
        if (
          data.status.indicator !== "none" &&
          data.status.indicator !== "minor" &&
          data.status.incidents.length > 0
        ) {
          setShowOpenaiAlert(true);
        } else {
          setShowOpenaiAlert(false);
        }
      } else {
        console.error("Failed to fetch status data");
      }
    } catch (error) {
      console.error("Error fetching status data:", error);
    }
  };

  useEffect(() => {
    const { pathname } = window.location;
    if (pathname !== '/privacy' && pathname !== '/terms-of-service') {
      if (messaging) {
        generateToken({setFCMToken, removeFCMToken});
        onMessage(messaging, (payload) => {
          console.log("Message received. ", payload);
          toast.info(
            <div>
              <p className="notification-title">
                {payload.notification.title}
              </p>
              <p className="notification-content">
                {payload.notification.body}
              </p>
            </div>
          );
        });
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      getUserBadgeAlerts()
        .unwrap()
        .then(function (res) {
          if (res && res.length > 0) {
            setBadgeAlerts(res);
            setIsShowBadgeAlert(true);
          }
        });
    }
    if (
      window.location.pathname == "/life-coach" &&
      sessions &&
      session_order &&
      Number(session_order) > 0
    ) {
      let selSessions = sessions.filter(
        (session) => session.order == Number(session_order)
      );
      if (selSessions.length > 0) {
        setSelectedSession(selSessions[0]);
        setShowSessionPopup(true);
      }
    }
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    fetchStatusData();
    const statusTimer = setInterval(() => {
      fetchStatusData();
    }, 60000 * openAiStatusCheckTime);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearInterval(statusTimer);
    };
  }, []);

  useEffect(() => {
    if (screenSize > 991) {
      dispatch({ type: SET_DESKTOP_MODE });
      // setIsMobile(false);
    } else {
      // setIsMobile(true);
      dispatch({ type: SET_MOBILE_MODE });
    }
  }, [screenSize]);

  const toggleDrawer = () => {
    if (open) {
      dispatch({ type: SET_CLOSE });
    } else {
      dispatch({ type: SET_OPEN });
    }
  };

  const getWidth = () => {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  };
  const closeDrawer = () => {
    const width = getWidth();
    if (width < 991) {
      dispatch({ type: SET_CLOSE });
    }
  };

  const [logout] = useLogoutMutation();

  const handleLogOut = async (e) => {
    try {
      const responseData = await logout().unwrap();
    } catch (err) {
      console.log("err", err);
    }
    dispatch({ type: LOG_OUT });
    navigate("/login", { replace: true });
  };

  const handleCloseBadgeAlertPopup = () => {
    setIsShowBadgeAlert(false);
    ackUserBadgeAlerts()
      .unwrap()
      .then(function (res) {
        console.log(res);
      })
      .then(function (err) {
        console.log(err);
      });
  };

  const handleSession = (session) => {
    setSelectedSession(session);
    setShowSessionPopup(true);
  };

  const handleCloseSessionPopup = () => {
    setShowSessionPopup(false);
    setSelectedSession(null);
  };
  const renderTakenTooltip = (props) => (
    <Tooltip id="session-completed-tooltip" {...props}>
      Session Completed: You've successfully completed this session. Feel free
      to review your session summary and continue with the next available
      session.
    </Tooltip>
  );
  const renderLockedFirstSessionTooltip = (props) => (
    <Tooltip id="session-completed-tooltip" {...props}>
      Before you proceed with Session 1, please watch the tutorial video at the
      bottom right – it's packed with tips to supercharge your Life Coach
      journey. Let's make this awesome together!
    </Tooltip>
  );
  const renderLockedTooltip = (props) => (
    <Tooltip id="session-completed-tooltip" {...props}>
      Homework from previous session must be completed and one day has elapsed.
      Only one session per day can be completed.
    </Tooltip>
  );

  const lifeCoachSessionCheck = (order) => {
    if (isWatchedLifeCoachTutorial && order == 4) {
      return true;
    }
    if (order != lastSessionOrder + 1 || isWatchedLifeCoachTutorial == false) {
      return false;
    }
    if (order > 1 && goalCount == 0) {
      return false;
    }
    if (order == 3 && isActionPlanAccepted == false) {
      return false;
    }

    return true;
  };

  return (
    <>
      <Analytics />
      {user && (
        <div className="quize_dashboard">
          <div className={open ? "left-sidenav sidemenu-open" : "left-sidenav"}>
            <div>
              <div className="brand text-center">
                <NavLink to="/dashboard" className="logo">
                  <img src={logoWhiteSvg} alt="Mosaic Life" />
                </NavLink>
                {isMobile && (
                  <img
                    src={closeSvg}
                    className="left-sidenav-close"
                    alt="Close"
                    onClick={toggleDrawer}
                  />
                )}
              </div>

              <div className="menu-content">
                <div className="menu-nav-link">
                  {menuList.map((item) => {
                    return (
                        <NavLink
                          key={item.url}
                          className={
                            "menu-item" + (
                              window.location.pathname == item.url ||
                              (item.startwith &&
                                window.location.pathname.startsWith(
                                  item.startwith
                                )) ||
                              (item.includeList &&
                                item.includeList.includes(
                                  window.location.pathname
                                ))
                                ? " active"
                                : ""
                            )
                          }
                          to={item.url}
                        >
                          <img src={item.svg} />
                          <p>{item.title}</p>
                        </NavLink>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="menu-user">
              {menuUserList.map((item) => {
                const isExternal = item?.url?.startsWith('http');
                const linkProps = isExternal ? { target: "_blank", rel: "noopener noreferrer" } : {};

                return (
                    <NavLink
                      key={item.url}
                      className={
                        "menu-item" + (
                          window.location.pathname == item.url ||
                          (item.startwith &&
                            window.location.pathname.startsWith(
                              item.startwith
                            )) ||
                          (item.includeList &&
                            item.includeList.includes(
                              window.location.pathname
                            ))
                            ? " active"
                            : ""
                        )
                      }
                      to={item.url}
                      {...linkProps}
                    >
                      <img src={item.svg} />
                      <p>{item.title}</p>
                    </NavLink>
                );
              })}
              <button className="btn-menu-item" onClick={() => handleLogOut()}>
                <img src={logoutIcon}/>
                <p>Logout</p>
              </button>
            </div>
          </div>

          <div className={open ? "page-wrapper sidemenu-open" : "page-wrapper"}>
            <div className="topbar-container">
              {
                open ? null : (
                  <>
                  <NavLink to="/dashboard" className="logo-mobile">
                    <img src={logoWhiteSvg} alt="Mosaic Life" />
                  </NavLink>

                  <button
                    className="toggle-btn"
                    onClick={toggleDrawer}
                  >
                    <svg
                      viewBox="0 0 32 32"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#11393E"
                    >
                      <path d="m30 7a1 1 0 0 1 -1 1h-26a1 1 0 0 1 0-2h26a1 1 0 0 1 1 1zm-5 8h-22a1 1 0 0 0 0 2h22a1 1 0 0 0 0-2zm-9 9h-13a1 1 0 0 0 0 2h13a1 1 0 0 0 0-2z" />
                    </svg>
                  </button>
                  </>
                )
              }
            </div>
            <div className="page-content" onClick={closeDrawer}>
              {children}
            </div>
          </div>
          {/* <!-- end page-wrapper --> */}
        </div>
      )}

      <BadgeAlertPopup
        show={isShowBadgeAlert}
        userBadges={badgeAlerts}
        handleCloseBadgeAlertPopup={handleCloseBadgeAlertPopup}
      />
      <LifeCoachSessionPopup
        show={showSessionPopup}
        handlePopupClose={handleCloseSessionPopup}
        session={selectedSession}
      />

      <ToastContainer
        position="top-right"
        autoClose={20000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};
export default MainLayout;
