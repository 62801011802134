import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


const CookieConsentComponent = () => {
    const [accepted, setAccepted] = useState(false)
    const [btnClass, setBtnClass] = useState('disabled')
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setAccepted(true)
            setBtnClass('')
        } else {
            setAccepted(false)
            setBtnClass('disabled')
        }
    }

    return (
        <CookieConsent
            location="bottom"
            buttonText="Continue"
            buttonClasses={btnClass}
            cookieName="mosaiclifeCookie"
            style={{ background: '#11393E', padding: '1.5rem 5.5rem 1.5rem 5.5rem', display: 'block', textAlign:'center' }}
            buttonStyle={{ color: 'black', backgroundColor: '#d7a03e', fontSize: '1.5rem', borderRadius:'5px', padding:'8px 30px', borderRadius:'0.5rem' }}
            expires={150}
            overlay
        >
            <Row className='text-center mx-4'>
                <Col md={12} style={{fontSize: '1.8rem'}}>
                    Mosaic Life uses cookies to ensure you get the best experience on our app. These cookies are essential for the app's functionality and performance. For more information on how we use cookies and how you can manage them, please read our <a href='/privacy' target='_blank' style={{ textDecoration: 'underline', color:'#0d6efd' }}>Privacy Policy</a>.
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col md={12} className='text-center'>
                    <div className='form-check'>
                        <input
                            className="form-check-input prompt-checkbox mr-2"
                            type="checkbox"
                            value={accepted}
                            id="agree"
                            style={{float: 'none', marginLeft: '0', marginRight:'1rem'}}
                            checked={accepted}
                            onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label" for="agree">
                            I agree to the use of cookies
                        </label>


                    </div>
                </Col>

            </Row>
        </CookieConsent>
    );
};

export default CookieConsentComponent;