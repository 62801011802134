import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Button } from "react-bootstrap";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import "./../css/life-coach-custom-session2-component.css";
import SessionMessageListComponent from "./SessionMessageListComponent";
import SessionChatInputComponent from "./SessionChatInputComponent";

import {
  useGetUserSessionMutation,
  useSendCustomSessionMessageMutation,
  useGenerateSessionSummaryMutation,
  useAssistantDialogPromptMutation,
  useAssistantPkbMutation,
} from "../slices/lifeCoachApiSlice";
import SessionBotProcessingComponent from "./SessionBotProcessingComponent";
import SessionWelcomeBackStartNewComponent from "./SessionWelcomeBackStartNewComponent";

const FAST_SPEED = 8;
const DEFAULT_QUESTION = "[USER_FIRST_NAME], how can I assist you today?";

const LifeCoachCustomSession4Component = ({ session, handlePopupClose }) => {
  const user = useSelector((state) => state.auth.user);
  const first_question = DEFAULT_QUESTION.replace(
    "[USER_FIRST_NAME]",
    user.first_name
  );
  const userLogoName = user.first_name.substring(0, 2).toUpperCase();
  const [forceRender, setForceRender] = useState(true);

  const [userSessionHistory, setUserSessionHistory] = useState(null);
  const [messages, setMessages] = useState(null);
  const [question, setQuestion] = useState(first_question);
  const [isProcessing, setIsProcessing] = useState(false);
  const [sessionFinished, setSessionFinished] = useState(false);
  const [sessionEnded, setSessionEnded] = useState(false);
  const [isShowChat, setIsShowChat] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [isContinue, setIsContinue] = useState(false);
  const [overlayShow, setOverlayShow] = useState(false);

  const [input, setInput] = useState("");
  const [step, setStep] = useState(null);
  const [currentChoice, setCurrentChoice] = useState("");
  const chatInputRef = useRef(null);
  const closeBtnRef = useRef(null);
  const scrollRef = useRef(null);

  const [getUserSession] = useGetUserSessionMutation();
  const [sendSessionMessage] = useSendCustomSessionMessageMutation();
  const [generateSessionSummary] = useGenerateSessionSummaryMutation();
  const [assistantDialogPrompt] = useAssistantDialogPromptMutation();
  const [assistantPkb] = useAssistantPkbMutation();

  useEffect(() => {
    if (userSessionHistory == null) {
      getUserSession({ session_id: session.id })
        .unwrap()
        .then(function (res) {
          setUserSessionHistory(res);
          setMessages(null);
          if (res.messages && res.messages.length > 0) {
            setIsContinue(true);
          } else {
            setQuestion(first_question);
            setIsContinue(false);
            setStep(0);
          }
        });
    }
  }, []);

  useEffect(() => {
    const writeMessages = async (elements) => {
      setIsTyping(false);
      setIsShowChat(false);
      for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        let msg = element.content;
        let typeSpeed = 15;
        if (element.speed) {
          typeSpeed = element.speed;
        }
        console.log("element", element);
        if (element.effect == "type") {
          if (messages != null || i != 0) {
            if (element.type == "text" || element.type == "comment") {
              setMessages((prev) => [
                ...prev,
                {
                  role: "bot",
                  content: "",
                  type: "text",
                  effect: element.effect,
                  showLogo: element.showLogo,
                },
              ]);
            } else if (element.type == "radiogroup") {
              setMessages((prev) => [
                ...prev,
                {
                  role: "bot",
                  content: "",
                  type: "text",
                  choices: element.choices,
                  effect: element.effect,
                  showLogo: element.showLogo,
                },
              ]);
            } else if (element.type == "button") {
              setMessages((prev) => [
                ...prev,
                {
                  role: "bot",
                  content: "",
                  type: "text",
                  showLogo: element.showLogo,
                },
              ]);
            }
          } else {
            if (element.type == "text") {
              setMessages([
                {
                  role: "bot",
                  content: "",
                  type: "text",
                  showLogo: element.showLogo,
                },
              ]);
            } else {
              setMessages([
                {
                  role: "bot",
                  content: "",
                  choices: element.choices,
                  type: "text",
                  showLogo: element.showLogo,
                },
              ]);
            }
          }
          let charLen = 0;
          for (let char of msg) {
            charLen += 1;
            if (charLen > 100) {
              scrollRef.current?.scrollIntoView({ block: "end" });
              charLen = 0;
            }
            setMessages((prev) => {
              let newMessages = [...prev];
              newMessages[newMessages.length - 1].content += char;
              return newMessages;
            });
            await new Promise((resolve) => setTimeout(resolve, typeSpeed));
          }
          setMessages((prev) => {
            let newMessages = [...prev];
            newMessages[newMessages.length - 1].type = element.type;

            return newMessages;
          });
        } else if (element.effect == "put") {
          setMessages((prev) => [...prev, element]);
          await new Promise((resolve) => setTimeout(resolve, 25));
        } else if (element.effect == "delay") {
          await new Promise((resolve) =>
            setTimeout(resolve, 1000 * element.time)
          );
        } else if (element.effect == "set_step") {
          setStep(element.step);
        }
        setIsTyping(true);
        setTimeout(() => {
          scrollToBottom();
        }, 100);
        setTimeout(() => {
          if (chatInputRef.current) {
            chatInputRef.current.focus();
          }
        }, 300);
      }
    };
    if (step != null) {
      setIsShowChat(false);
      let elements = [];
      let content = "";
      let element = null;
      if (step == 0) {
        element = {
          role: "bot",
          content: question,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        elements.push(element);
        writeMessages(elements);
        setIsShowChat(true);
      } else if (step == 1) {
        let user_request = input;
        if (user_request && user_request != "") {
          let userMessage = { role: "user", content: input };
          setMessages([...messages, userMessage]);

          setInput("");
          setTimeout(() => {
            scrollToBottom();
          }, 100);

          const latestTen = [];
          const startIndex = Math.max(messages.length - 10, 0);

          for (let i = startIndex; i < messages.length; i++) {
            latestTen.push(messages[i]);
          }
          if (latestTen[0].role == "user") {
            latestTen.shift();
          }

          setIsProcessing(true);
          assistantDialogPrompt({
            session_id: session.id,
            question: question,
            user_answer: user_request,
            messages: latestTen,
          })
            .unwrap()
            .then(function (res) {
              setIsProcessing(false);
              if (res.summary_text) {
                content = res.summary_text;
                setQuestion(res.summary_text);
                element = {
                  role: "bot",
                  content: "",
                  effect: "set_step",
                  step: 0,
                };
                elements.push(element);
              } else {
                content =
                  "We encountered an unexpected issue. Please try again later.";
                element = {
                  role: "bot",
                  content: content,
                  type: "text",
                  effect: "type",
                  showLogo: true,
                };
                elements.push(element);
              }
              writeMessages(elements);
            })
            .catch(function (err) {
              setIsProcessing(false);
              content =
                "We encountered an unexpected issue. Please try again later.";
              element = {
                role: "bot",
                content: content,
                type: "text",
                effect: "type",
                showLogo: true,
              };
              elements.push(element);
              writeMessages(elements);
            });
        }
      }

      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }, [step, forceRender]);

  const handleSend = async () => {
    setStep(step + 1);
    setIsShowChat(false);
  };

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ block: "end" });
  };

  const handleContinue = () => {
    setIsContinue(false);
    setQuestion(userSessionHistory.messages[0].content);
    setStep(0);
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const handleStartNew = () => {
    setQuestion(first_question);
    setIsContinue(false);
    setStep(0);
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const selectChoice = async (choice) => {
    setMessages((prevList) => {
      if (prevList.length === 0) return [choice]; // handle case where list is empty

      const newList = [...prevList]; // create a shallow copy
      newList[newList.length - 1].selected_choice = choice.value; // change the last item
      return newList;
    });
    setCurrentChoice(choice);
    setStep(choice.step);
    sendSessionMessage({ session_id: session.id, choice: choice.value })
      .unwrap()
      .then(function (res) {
        setIsProcessing(false);
      });
  };

  const handleFinishSession = () => {
    setSessionEnded(true);
    generateSessionSummary({ session_id: session.id });
    scrollToBottom();
  };

  const handleFinish = () => {
    window.location.href = "/life-coach";
  };

  const handleChatContinue = () => {
    setForceRender(!forceRender);
    const newMessages = messages.map((message) => {
      if (message.type == "button") {
        return { ...message, role: "user", type: "text" };
      }
      return message;
    });

    setMessages(newMessages);
  };

  const handlePause = () => {
    if (messages && messages.length > 1) {
      assistantPkb({ messages: messages }).unwrap();
    }
    setTimeout(() => {
      handlePopupClose();
    }, 100);
  };

  const renderTakenTooltip = (props) => (
    <Tooltip id="session-completed-tooltip" {...props}>
      When you return, you will have the option to continue this session or
      start a new session.
    </Tooltip>
  );

  return (
    <div className="life-coach-session-component">
      {isContinue == true ? (
        <>
          <SessionWelcomeBackStartNewComponent />
          <Row className="mt-4">
            <Col className="text-center">
              <Button
                className="btn-yellow mt-3 start-new-btn"
                size="lg"
                onClick={() => handleStartNew()}
              >
                START NEW
              </Button>
              <Button
                className="btn-yellow mt-3"
                size="lg"
                onClick={() => handleContinue()}
              >
                CONTINUE
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {userSessionHistory && (
            <SessionMessageListComponent
              isShowChat={isTyping}
              messages={messages}
              userLogoName={userLogoName}
              selectChoice={selectChoice}
              handleContinue={handleChatContinue}
              forceRender={forceRender}
            />
          )}
          {sessionEnded == false && isProcessing == true ? (
            <SessionBotProcessingComponent />
          ) : (
            isShowChat &&
            isTyping && (
              <SessionChatInputComponent
                chatInputRef={chatInputRef}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onSend={handleSend}
              />
            )
          )}
          {sessionFinished == true && (
            <h6
              style={{
                textAlign: "center",
                color: "white",
                marginTop: "2rem",
                marginBottom: "4rem",
              }}
            >
              this session was already taken
            </h6>
          )}

          {sessionEnded == true ? (
            <Row className="mt-4">
              <Col className="text-center">
                <Button
                  className="btn-yellow mt-3"
                  ref={closeBtnRef}
                  size="lg"
                  onClick={() => handleFinish()}
                >
                  CONTINUE
                </Button>
              </Col>
            </Row>
          ) : (
            <Row className="mt-4">
              <Col className="text-center">
                <a
                  onMouseEnter={() => setOverlayShow(true)}
                  onMouseLeave={() => setOverlayShow(false)}
                >
                  <Button
                    className="btn-yellow mt-3"
                    ref={closeBtnRef}
                    size="lg"
                    onClick={() => handlePause()}
                  >
                    EXIT SESSION
                  </Button>
                </a>
                <Overlay
                  target={closeBtnRef.current}
                  show={overlayShow}
                  placement="top"
                >
                  {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                  }) => (
                    <div
                      {...props}
                      style={{
                        position: "absolute",
                        zIndex: 10000,
                        marginBottom: "1rem",
                        backgroundColor: "rgba(255, 100, 100, 1)",
                        padding: "10px 10px",
                        color: "white",
                        borderRadius: 3,
                        ...props.style,
                      }}
                    >
                      <p style={{ fontWeight: "bold", fontSize: "1.7rem" }}>
                        When you return, you will have the option to continue
                        this session or start a new session.
                      </p>
                    </div>
                  )}
                </Overlay>
              </Col>
            </Row>
          )}
        </>
      )}
      <span ref={scrollRef}></span>
    </div>
  );
};

export default LifeCoachCustomSession4Component;
