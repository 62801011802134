import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import botPng from './../../../assets/images/bot.png'
import './../css/session-message-component.css'
import SessionTagListComponent from './SessionTagListComponent';

const SessionMessageComponent = ({ isShowChat, userLogoName, message, selectChoice, showLogo, handleContinue, forceRender }) => {
    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, []);
    const getContent = () => {
        let content = ""
        if (message.reflection && message.reflection != '') {
            content = message.reflection + "\n\n"
        }
        content += message.content
        return content;
    }
    return (
        <div className={`session-message-component session-message-component-${message.role}`}>
            <div className={`message ${message.role}`}>
                {showLogo ?
                    <>
                        {message.role == 'bot' ?
                            <img className='bot-logo' src={botPng}></img>
                            :
                            <span className='user-name'>{userLogoName}</span>
                        }
                    </>
                    :
                    <>
                        <span className='empty-space'></span>
                    </>
                }
                {message.type == 'radiogroup' ?
                    <div>
                        <div className={`message-content ${message.role}`} dangerouslySetInnerHTML={{ __html: getContent() }} ></div>
                        {(message.selected_choice || isShowChat) && <SessionTagListComponent choices={message.choices} selectChoice={selectChoice} selectedChoice={message.selected_choice} />}
                    </div>
                    :
                    message.type == 'button' ?
                        <div className='continue-btn-div'>
                            <Button className='continue-btn mt-3' size="lg" onClick={() => handleContinue()} >
                                {message.content && message.content != '' ? message.content : 'CONTINUE'}
                            </Button>
                        </div>
                        :
                        <div className={`message-content ${message.role}`} dangerouslySetInnerHTML={{ __html: getContent() }} ></div>
                }
            </div>
        </div>

    )

}

export default SessionMessageComponent;