import React, { useRef, useEffect } from 'react';
import sendPng from './../../../assets/images/send.png'
import './../css/session-chat-input-component.css'


const SessionChatInputComponent = ({ chatInputRef, value, onChange, onSend }) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();  // prevent the newline character
            onSend();
        }
    };
    useEffect(() => {
        const textarea = chatInputRef.current;
        textarea.style.height = "auto"; // reset height
        textarea.style.height = textarea.scrollHeight + "px"; // set to scroll height
    }, [value]);


    return (
        <div className="session-chat-input-component">
            <textarea
                ref={chatInputRef}
                className="chat-textarea"
                value={value}
                onChange={onChange}
                onKeyPress={handleKeyPress}
                placeholder="Enter message"
                rows="1" // start with a single row
            />
            <img className={!value.trim() ? `send-button disabled` : `send-button`} src={sendPng} onClick={onSend} disabled={!value.trim()}></img>
        </div>

    )

}

export default SessionChatInputComponent;