import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import { Col, Row, Button } from 'react-bootstrap';
import BadgeComponentInPopup from '../BadgeComponentInPopup';
import './../css/badge-alert-popup.css'

export default function BadgeAlertPopup(props) {
    const [show, setShow] = useState(props.show);
    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (

        <>
            <Modal
                show={show}
                onHide={props.handleCloseBadgeAlertPopup}
                backdrop="static"
                size=""
                keyboard={false}
                className='badge-alert-popup'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Congratulations!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.userBadges && props.userBadges.map((userBadge) => (
                        <>
                            <Row className='mt-4'>
                                <Col>
                                    <BadgeComponentInPopup userBadge={userBadge} />
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>
                                    <hr></hr>
                                </Col>
                            </Row>
                        </>
                    ))}

                    <Row className='mt-4'>
                        <Col className="text-center">
                            <Button className='btn-yellow mt-3' size="lg" onClick={() => props.handleCloseBadgeAlertPopup()} >
                                OK
                            </Button>

                        </Col>
                    </Row>


                </Modal.Body>
            </Modal>
        </>
    );
}