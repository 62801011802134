import React from 'react'
import './../../assets/css/verify.css';

const VerifyLayout = ({ children }) => {

    return(<>
    
    <div className="verify-container">
		<div className="container">
			<div className="verify-acc">
                {children}
			</div>
		</div>
	</div>
    </>)
}

export default VerifyLayout;