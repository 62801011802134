import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Button } from "react-bootstrap";
import "./../css/life-coach-custom-session2-component.css";
import SessionMessageListComponent from "./SessionMessageListComponent";
import SessionChatInputComponent from "./SessionChatInputComponent";

import {
  useGetUserSessionMutation,
  useSendCustomSessionMessageMutation,
  useGenerateSessionSummaryMutation,
  useGetUserAnalyzedGoalsMutation,
  useGetUpdateGoalMutation,
  useGetSmartCriteriaEvaluationMutation,
  useGetIntroductionValueAlignmentMutation,
  useDeleteGoalMutation,
  useUpdateGoalActionMutation,
} from "../slices/lifeCoachApiSlice";
import SessionBotProcessingComponent from "./SessionBotProcessingComponent";
import SessionWelcomeBackComponent from "./SessionWelcomeBackComponent";

const botMessages = [
  "Welcome back, [USER_FIRST_NAME]! In Session 2, we will refine and enhance the goals you've set to finalize your Action Plan. This refinement is an important part of your journey to success.",
  "Here are the goals you have selected:",
  "We’ll review these one at a time to make sure they are ready for you to implement.",
  "Here are your goal details:",
  "And here is an analysis of this goal using the SMART criteria:",
  "Would you like to refine your goal based on this feedback, or do you want to keep it as is?",
  "What changes would you like to make?",
  "Got it. Here's the updated information based on your input:",
  "Is this correct? If there are more changes, please specify.",
  "Sounds great, [USER_FIRST_NAME]! Now, let's dive deeper into how your goal resonates with your core values. Aligning your goal with your values can greatly enhance your motivation and commitment. Let’s first review your Assessment Report…",
  "Would you like to make any changes to your goal;",
  "Are you sure you want to remove your goal:",
  "… from your Action Plan? Note: once confirmed this action is irreversible.",
  "Okay, your goal to [GOAL], has been removed from your Action Plan.",
  "This goal, [GOAL], will be kept in your Action Plan.",
  "Before we can complete your Action Plan, we want to be sure your action steps have been clearly defined for you to achieve each goal.",
  "For your goal, [GOAL] you have identified the following action steps:",
  "Would you like to make any changes to these actions?",
];

const conclusionMessages = [
  "Thank you for your dedication and insights during today's session. Let's recap what we've covered:",
  "•	We critically assessed your goals to ensure they are SMART: Specific, Measurable, Achievable, Relevant, and Time-bound.",
  "•	We aligned your goals with your core personal values, ensuring a deeper connection and motivation.",
  "•	We verified that the action steps you've laid out are sufficient to guide you towards achieving each of your goals.",
  "<strong>Your Next Steps (Homework):</strong>",
  "<strong>1.	Review Session Summary:</strong> Take some time to go through the summary of Session 2. Reflect on the insights and clarifications discussed.",
  "<strong>2.	Acceptance of Revised Action Plan:</strong> After reflecting, please accept the refined action plan within the platform.",
  "<strong>3.	Progress Tracking:</strong> Dedicate at least one day to log your progress in the Progress Tracker on the Action Plan page. This will give you a clear picture of your journey.",
  "<strong>4.	Journaling:</strong> Consider creating at least one journal entry. Capture your feelings, insights, and any moments of realization from today.",
  "<strong>Looking Ahead to Session 3:</strong>",
  "In our next session:",
  "•	We will explore potential obstacles that might hinder your progress and strategize on overcoming them.",
  "•	We'll identify valuable resources that can bolster your journey towards your goals.",
  "•	Most importantly, we'll establish an accountability plan, ensuring you stay on course and remain motivated.",
  "Please ensure a minimum of one day between this session and Session 3, giving you adequate time to reflect, track, and prepare.",
  "Your proactive engagement today has set the foundation for the success to come. Remember, every step taken with clarity and purpose is a step closer to your goals. See you in Session 3!",
];

const FAST_SPEED = 5;

const LifeCoachCustomSession2Component = ({ session, handlePopupClose }) => {
  const user = useSelector((state) => state.auth.user);
  const userLogoName = user.first_name.substring(0, 2).toUpperCase();
  const [goalData, setGoalData] = useState(null);
  const [userSessionHistory, setUserSessionHistory] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [input, setInput] = useState("");

  const [currentGoalIdx, setCurrentGoalIdx] = useState(null);
  const [forceRender, setForceRender] = useState(true);
  const [messages, setMessages] = useState(null);
  const [sessionFinished, setSessionFinished] = useState(false);
  const [sessionEnded, setSessionEnded] = useState(false);
  const [isShowChat, setIsShowChat] = useState(false);
  const [step, setStep] = useState(null);
  const [currentChoice, setCurrentChoice] = useState("");

  const chatInputRef = useRef(null);
  const closeBtnRef = useRef(null);
  const scrollRef = useRef(null);

  const [getUserAnalyzedGoals] = useGetUserAnalyzedGoalsMutation();
  const [getUserSession] = useGetUserSessionMutation();
  const [sendSessionMessage] = useSendCustomSessionMessageMutation();
  const [generateSessionSummary] = useGenerateSessionSummaryMutation();
  const [getUpdateGoal] = useGetUpdateGoalMutation();
  const [getSmartCriteriaEvaluation] = useGetSmartCriteriaEvaluationMutation();
  const [getIntroductionValueAlignment] =
    useGetIntroductionValueAlignmentMutation();
  const [deleteGoal] = useDeleteGoalMutation();
  const [updateGoalAction] = useUpdateGoalActionMutation();
  useEffect(() => {
    if (goalData == null) {
      getUserAnalyzedGoals()
        .unwrap()
        .then(function (res) {
          if (res) {
            setGoalData(res);
            setCurrentGoalIdx(0);
            if (userSessionHistory == null) {
              getUserSession({ session_id: session.id })
                .unwrap()
                .then(function (res) {
                  if (res.is_finished == false) {
                    setUserSessionHistory(res);
                    if (res.messages && res.messages.length > 0) {
                      setIsContinue(true);
                      setMessages(res.messages);
                      setStep(res.step);
                      setCurrentGoalIdx(res.goal_idx);
                      scrollToBottom();
                    } else {
                      setStep(0);
                    }
                  } else {
                    setSessionFinished(true);
                  }
                });
            }
          }
        });
    }
  }, []);

  useEffect(() => {
    const writeMessages = async (elements) => {
      setIsTyping(false);
      setIsShowChat(false);
      for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        let typeSpeed = 15;
        if (element.speed) {
          typeSpeed = element.speed;
        }
        let msg = element.content;
        console.log("element", element);
        if (element.effect == "type") {
          if (messages != null || i != 0) {
            if (element.type == "text" || element.type == "comment") {
              setMessages((prev) => [
                ...prev,
                {
                  role: "bot",
                  content: "",
                  type: "text",
                  effect: element.effect,
                  showLogo: element.showLogo,
                },
              ]);
            } else if (element.type == "radiogroup") {
              setMessages((prev) => [
                ...prev,
                {
                  role: "bot",
                  content: "",
                  type: "text",
                  choices: element.choices,
                  effect: element.effect,
                  showLogo: element.showLogo,
                },
              ]);
            } else if (element.type == "button") {
              setMessages((prev) => [
                ...prev,
                {
                  role: "bot",
                  content: "",
                  type: "text",
                  showLogo: element.showLogo,
                },
              ]);
            }
          } else {
            if (element.type == "text") {
              setMessages([
                {
                  role: "bot",
                  content: "",
                  type: "text",
                  showLogo: element.showLogo,
                },
              ]);
            } else {
              setMessages([
                {
                  role: "bot",
                  content: "",
                  choices: element.choices,
                  type: "text",
                  showLogo: element.showLogo,
                },
              ]);
            }
          }
          let charLen = 0;
          for (let char of msg) {
            charLen += 1;
            if (charLen > 100) {
              scrollRef.current?.scrollIntoView({ block: "end" });
              charLen = 0;
            }
            setMessages((prev) => {
              let newMessages = [...prev];
              newMessages[newMessages.length - 1].content += char;
              return newMessages;
            });
            await new Promise((resolve) => setTimeout(resolve, typeSpeed));
          }
          setMessages((prev) => {
            let newMessages = [...prev];
            newMessages[newMessages.length - 1].type = element.type;

            return newMessages;
          });
        } else if (element.effect == "put") {
          setMessages((prev) => [...prev, element]);
          await new Promise((resolve) => setTimeout(resolve, 25));
        } else if (element.effect == "delay") {
          await new Promise((resolve) =>
            setTimeout(resolve, 1000 * element.time)
          );
        } else if (element.effect == "set_step") {
          setStep(element.step);
        }
        setTimeout(() => {
          scrollToBottom();
        }, 100);
        setTimeout(() => {
          if (chatInputRef.current) {
            chatInputRef.current.focus();
          }
        }, 300);
      }
      setIsTyping(true);
    };
    if (step != null) {
      setIsShowChat(false);
      let elements = [];
      let content = "";
      let element = null;
      if (step == 0) {
        let newMessages = [];
        content = botMessages[0].replace("[USER_FIRST_NAME]", user.first_name);
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newMessages.push(element);
        elements.push(element);
        if (goalData.length > 1) {
          content = botMessages[1] + "\n";
          for (let i = 0; i < goalData.length; i++) {
            content += "· " + goalData[i].goal + "\n";
          }
          element = {
            role: "bot",
            content: content,
            type: "text",
            effect: "type",
            showLogo: false,
          };
          newMessages.push(element);
          elements.push(element);

          element = { role: "bot", content: "", effect: "delay", time: 3 };
          elements.push(element);

          content = botMessages[2];
          element = {
            role: "bot",
            content: content,
            type: "text",
            effect: "type",
            showLogo: false,
          };
          newMessages.push(element);
          elements.push(element);
        }
        element = { role: "bot", content: "", effect: "set_step", step: 1 };
        elements.push(element);

        writeMessages(elements);
        triggerSendMessage(newMessages);
      } else if (step == 1) {
        if (currentGoalIdx >= goalData.length) {
          element = { role: "bot", content: "", effect: "set_step", step: 6 };
          elements.push(element);
          writeMessages(elements);
        } else {
          let newMessages = [];

          if (forceRender) {
            element = {
              role: "bot",
              content: "",
              type: "button",
              effect: "type",
              showLogo: true,
            };
            elements.push(element);
            writeMessages(elements);
            return;
          }

          if (currentGoalIdx > 0) {
            content = "Let's take a look at your next goal...";
            element = {
              role: "bot",
              content: content,
              type: "text",
              effect: "type",
              showLogo: true,
            };
            newMessages.push(element);
            elements.push(element);

            element = { role: "bot", content: "", effect: "delay", time: 1 };
            elements.push(element);
          }

          content =
            botMessages[3] +
            "\n\n" +
            "GOAL:\n" +
            goalData[currentGoalIdx].goal +
            "\n\n" +
            "ACTION:\n" +
            goalData[currentGoalIdx].action +
            "\n\n" +
            "BENEFITS:\n" +
            goalData[currentGoalIdx].benefits;
          element = {
            role: "bot",
            content: content,
            type: "text",
            effect: "put",
            showLogo: false,
          };
          newMessages.push(element);
          elements.push(element);

          writeMessages(elements);
          elements = [];

          setIsProcessing(true);
          getSmartCriteriaEvaluation({
            session_id: session.id,
            goal_id: goalData[currentGoalIdx].id,
          })
            .unwrap()
            .then(function (res) {
              setIsProcessing(false);
              if (res.summary_text && res.summary_text != "undefined") {
                content = botMessages[4] + "\n\n" + res.summary_text;
                element = {
                  role: "bot",
                  content: content,
                  type: "text",
                  effect: "type",
                  showLogo: true,
                };
                newMessages.push(element);
                elements.push(element);
              }

              element = { role: "bot", content: "", effect: "delay", time: 3 };
              elements.push(element);

              element = {
                role: "bot",
                content: "",
                effect: "set_step",
                step: 2,
              };
              elements.push(element);

              writeMessages(elements);

              triggerSendMessage(newMessages);
            });
        }
      } else if (step == 2) {
        let newMessages = [];
        content = botMessages[5];
        let choices = [
          { value: "Update this goal", text: "Update this goal", step: 3 },
          { value: "Keep it", text: "Keep it", step: 5 },
        ];
        element = {
          role: "bot",
          content: content,
          type: "radiogroup",
          choices: choices,
          effect: "type",
          showLogo: true,
        };
        newMessages.push(element);
        elements.push(element);
        writeMessages(elements);
        triggerSendMessage(newMessages);
      } else if (step == 3) {
        let newMessages = [];
        if (currentChoice.text && currentChoice.text != "") {
          let userMessage = { role: "user", content: currentChoice.text };
          newMessages.push(userMessage);
          setMessages([...messages, userMessage]);
          setCurrentChoice("");

          content = botMessages[6];
          element = {
            role: "bot",
            content: content,
            type: "text",
            effect: "type",
            showLogo: true,
          };
          elements.push(element);
          newMessages.push(element);
          writeMessages(elements);
          triggerSendMessage(newMessages);
        } else {
          element = { role: "bot", content: "", effect: "set_step", step: 2 };
          elements.push(element);
          writeMessages(elements);
        }
        setIsShowChat(true);
      } else if (step == 4) {
        let newMessages = [];
        let user_request = input;
        if (user_request && user_request != "") {
          let userMessage = { role: "user", content: input };
          newMessages.push(userMessage);
          setMessages([...messages, userMessage]);

          setInput("");
          setTimeout(() => {
            scrollToBottom();
          }, 100);

          setIsProcessing(true);
          getUpdateGoal({
            session_id: session.id,
            goal_id: goalData[currentGoalIdx].id,
            user_request: user_request,
          })
            .unwrap()
            .then(function (res) {
              setIsProcessing(false);
              console.log(res);
              const goal_id = goalData[currentGoalIdx].id;

              const modifiedGoals = goalData.map((goal) => {
                if (goal.id == goal_id) {
                  return {
                    ...goal,
                    goal: res.goal,
                    action: res.action,
                    benefits: res.benefits,
                  };
                }
                return goal;
              });
              setGoalData(modifiedGoals);
              content = botMessages[7];
              element = {
                role: "bot",
                content: content,
                type: "text",
                effect: "type",
                showLogo: true,
              };
              elements.push(element);
              newMessages.push(element);

              content =
                "GOAL:\n" +
                res.goal +
                "\n\nACTION:\n" +
                res.action +
                "\n\nEXPECTED BENEFITS:\n" +
                res.benefits;
              element = {
                role: "bot",
                content: content,
                type: "text",
                effect: "type",
                showLogo: true,
              };
              elements.push(element);
              newMessages.push(element);

              element = { role: "bot", content: "", effect: "delay", time: 3 };
              elements.push(element);

              let choices = [
                { value: "Correct", text: "Correct", step: 5 },
                { value: "More changes", text: "More changes", step: 3 },
              ];
              element = {
                role: "bot",
                content: botMessages[8],
                type: "radiogroup",
                choices: choices,
                effect: "type",
                showLogo: true,
              };
              newMessages.push(element);
              elements.push(element);

              writeMessages(elements);
              triggerSendMessage(newMessages);
            });
        }
      } else if (step == 5) {
        let newMessages = [];
        let userMessage = { role: "user", content: currentChoice.text };
        newMessages.push(userMessage);
        setMessages([...messages, userMessage]);
        setCurrentChoice("");
        triggerSendMessage(newMessages);
        setCurrentGoalIdx(currentGoalIdx + 1);
        setStep(1);

        /////////////////////////////////////////////////////// section 2 /////////////////////////
      } else if (step == 6) {
        setCurrentGoalIdx(0);
        let newMessages = [];
        content = botMessages[9];
        content = content.replace("[USER_FIRST_NAME]", user.first_name);
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newMessages.push(element);
        elements.push(element);

        setIsProcessing(true);
        getIntroductionValueAlignment({ session_id: session.id })
          .unwrap()
          .then(function (res) {
            setIsProcessing(false);
            element = {
              role: "bot",
              content: res.summary_text,
              type: "text",
              effect: "type",
              showLogo: false,
            };
            newMessages.push(element);
            elements.push(element);

            element = { role: "bot", content: "", effect: "set_step", step: 7 };
            elements.push(element);

            writeMessages(elements);
            triggerSendMessage(newMessages);
          });
      } else if (step == 7) {
        if (currentGoalIdx >= goalData.length) {
          element = { role: "bot", content: "", effect: "set_step", step: 16 };
          elements.push(element);
          writeMessages(elements);
        } else {
          let newMessages = [];
          content = botMessages[10] + "\n" + goalData[currentGoalIdx].goal;
          let choices = [
            { value: "No changes", text: "No changes", step: 8 },
            { value: "Remove goal", text: "Remove goal", step: 9 },
            { value: "Make changes", text: "Make changes", step: 12 },
          ];
          element = {
            role: "bot",
            content: content,
            type: "radiogroup",
            choices: choices,
            effect: "type",
            showLogo: true,
          };
          newMessages.push(element);
          elements.push(element);

          writeMessages(elements);
          triggerSendMessage(newMessages);
        }
      } else if (step == 8) {
        let newMessages = [];
        let userMessage = { role: "user", content: currentChoice.text };
        newMessages.push(userMessage);
        setMessages([...messages, userMessage]);
        setCurrentChoice("");
        setCurrentGoalIdx(currentGoalIdx + 1);
        setStep(7);
      } else if (step == 9) {
        let newMessages = [];
        let userMessage = { role: "user", content: currentChoice.text };
        newMessages.push(userMessage);
        setMessages([...messages, userMessage]);
        setCurrentChoice("");

        content = botMessages[11] + "\n" + goalData[currentGoalIdx].goal;

        let choices = [
          {
            value: "Yes, I am sure. Remove this goal",
            text: "Yes, I am sure. Remove this goal",
            step: 10,
          },
          { value: "Cancel request", text: "Cancel request", step: 11 },
        ];
        element = {
          role: "bot",
          content: content,
          type: "radiogroup",
          choices: choices,
          effect: "type",
          showLogo: false,
        };
        newMessages.push(element);
        elements.push(element);

        writeMessages(elements);
        triggerSendMessage(newMessages);
      } else if (step == 10) {
        let newMessages = [];
        let userMessage = { role: "user", content: currentChoice.text };
        newMessages.push(userMessage);
        setMessages([...messages, userMessage]);
        setCurrentChoice("");
        let oldGoal = goalData[currentGoalIdx].goal;
        deleteGoal({ goal_id: goalData[currentGoalIdx].id })
          .unwrap()
          .then(function (res) {
            if (res) {
              const updatedItems = goalData.filter(
                (item) => item.id !== goalData[currentGoalIdx].id
              );
              setGoalData(updatedItems);

              content = botMessages[13].replace("[GOAL]", oldGoal);
              element = {
                role: "bot",
                content: content,
                type: "text",
                effect: "type",
                showLogo: false,
              };
              newMessages.push(element);
              elements.push(element);
              element = {
                role: "bot",
                content: "",
                effect: "set_step",
                step: 7,
              };
              elements.push(element);

              writeMessages(elements);
              triggerSendMessage(newMessages);
            }
          });
      } else if (step == 11) {
        let newMessages = [];
        let userMessage = { role: "user", content: currentChoice.text };
        newMessages.push(userMessage);
        setMessages([...messages, userMessage]);
        setCurrentChoice("");
        let oldGoal = goalData[currentGoalIdx].goal;
        content = botMessages[14].replace("[GOAL]", oldGoal);
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newMessages.push(element);
        elements.push(element);

        element = { role: "bot", content: "", effect: "set_step", step: 7 };
        elements.push(element);

        setCurrentGoalIdx(currentGoalIdx + 1);

        writeMessages(elements);
        triggerSendMessage(newMessages);
      } else if (step == 12) {
        let newMessages = [];
        if (currentChoice.text && currentChoice.text != "") {
          let userMessage = { role: "user", content: currentChoice.text };
          newMessages.push(userMessage);
          setMessages([...messages, userMessage]);
          setCurrentChoice("");

          content = botMessages[6];
          element = {
            role: "bot",
            content: content,
            type: "text",
            effect: "type",
            showLogo: true,
          };
          elements.push(element);
          newMessages.push(element);
          writeMessages(elements);
          triggerSendMessage(newMessages);
        } else {
          element = { role: "bot", content: "", effect: "set_step", step: 7 };
          elements.push(element);
          writeMessages(elements);
        }
        setIsShowChat(true);
      } else if (step == 13) {
        let newMessages = [];
        let user_request = input;
        if (user_request && user_request != "") {
          let userMessage = { role: "user", content: input };
          newMessages.push(userMessage);
          setMessages([...messages, userMessage]);

          setInput("");
          setTimeout(() => {
            scrollToBottom();
          }, 100);

          setIsProcessing(true);
          getUpdateGoal({
            session_id: session.id,
            goal_id: goalData[currentGoalIdx].id,
            user_request: user_request,
          })
            .unwrap()
            .then(function (res) {
              setIsProcessing(false);
              console.log(res);
              const goal_id = goalData[currentGoalIdx].id;

              const modifiedGoals = goalData.map((goal) => {
                if (goal.id == goal_id) {
                  return {
                    ...goal,
                    goal: res.goal,
                    action: res.action,
                    benefits: res.benefits,
                  };
                }
                return goal;
              });
              setGoalData(modifiedGoals);
              content = botMessages[7];
              element = {
                role: "bot",
                content: content,
                type: "text",
                effect: "type",
                showLogo: true,
              };
              elements.push(element);
              newMessages.push(element);

              content =
                "GOAL:\n" +
                res.goal +
                "\n\nACTION:\n" +
                res.action +
                "\n\nEXPECTED BENEFITS:\n" +
                res.benefits;
              element = {
                role: "bot",
                content: content,
                type: "text",
                effect: "type",
                showLogo: true,
              };
              elements.push(element);
              newMessages.push(element);

              element = { role: "bot", content: "", effect: "delay", time: 3 };
              elements.push(element);

              content = botMessages[8];
              element = {
                role: "bot",
                content: content,
                type: "text",
                effect: "type",
                showLogo: true,
              };
              newMessages.push(element);

              let choices = [
                { value: "Correct", text: "Correct", step: 14 },
                { value: "More changes", text: "More changes", step: 15 },
              ];
              element = {
                role: "bot",
                content: content,
                type: "radiogroup",
                choices: choices,
                effect: "type",
                showLogo: true,
              };
              newMessages.push(element);
              elements.push(element);

              writeMessages(elements);
              triggerSendMessage(newMessages);
            });
        }
      } else if (step == 14) {
        let newMessages = [];
        if (currentChoice.text && currentChoice.text != "") {
          let userMessage = { role: "user", content: currentChoice.text };
          newMessages.push(userMessage);
          setMessages([...messages, userMessage]);
          setCurrentChoice("");

          element = { role: "bot", content: "", effect: "set_step", step: 7 };
          elements.push(element);
          setCurrentGoalIdx(currentGoalIdx + 1);

          writeMessages(elements);
          triggerSendMessage(newMessages);
        } else {
          element = { role: "bot", content: "", effect: "set_step", step: 13 };
          elements.push(element);
          writeMessages(elements);
        }
      } else if (step == 15) {
        let newMessages = [];
        if (currentChoice.text && currentChoice.text != "") {
          let userMessage = { role: "user", content: currentChoice.text };
          newMessages.push(userMessage);
          setMessages([...messages, userMessage]);
          setCurrentChoice("");

          element = { role: "bot", content: "", effect: "set_step", step: 12 };
          elements.push(element);

          writeMessages(elements);
          triggerSendMessage(newMessages);
        } else {
          element = { role: "bot", content: "", effect: "set_step", step: 13 };
          elements.push(element);
          writeMessages(elements);
        }

        //////////////////////////// section 3 ///////////////////////////////////
      } else if (step == 16) {
        setCurrentGoalIdx(0);
        let newMessages = [];
        content = botMessages[15];
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newMessages.push(element);
        elements.push(element);

        element = { role: "bot", content: "", effect: "set_step", step: 17 };
        elements.push(element);

        writeMessages(elements);
        triggerSendMessage(newMessages);
      } else if (step == 17) {
        if (currentGoalIdx >= goalData.length) {
          element = { role: "bot", content: "", effect: "set_step", step: 21 };
          elements.push(element);
          writeMessages(elements);
        } else {
          let newMessages = [];
          let goal_text = goalData[currentGoalIdx].goal;
          goal_text = goal_text.trim();
          const lastLetter = goal_text.charAt(goal_text.length - 1);
          if (lastLetter == ".") {
            goal_text = goal_text.slice(0, -1);
          }
          goal_text += ", ";

          content = botMessages[16].replace("[GOAL]", goal_text);
          content += "\n" + goalData[currentGoalIdx].action;
          element = {
            role: "bot",
            content: content,
            type: "text",
            effect: "type",
            speed: FAST_SPEED,
            showLogo: true,
          };
          newMessages.push(element);
          elements.push(element);

          element = { role: "bot", content: "", effect: "delay", time: 3 };
          elements.push(element);

          content = botMessages[17];
          let choices = [
            { value: "Update actions", text: "Update actions", step: 19 },
            { value: "No changes", text: "No changes", step: 18 },
          ];
          element = {
            role: "bot",
            content: content,
            type: "radiogroup",
            choices: choices,
            effect: "type",
            showLogo: false,
          };
          newMessages.push(element);
          elements.push(element);

          writeMessages(elements);
          triggerSendMessage(newMessages);
        }
      } else if (step == 18) {
        setCurrentGoalIdx(currentGoalIdx + 1);
        element = { role: "bot", content: "", effect: "set_step", step: 17 };
        elements.push(element);
        writeMessages(elements);
      } else if (step == 19) {
        let newMessages = [];
        content = botMessages[6];
        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        newMessages.push(element);
        elements.push(element);
        writeMessages(elements);
        triggerSendMessage(newMessages);
        setIsShowChat(true);
      } else if (step == 20) {
        if (currentGoalIdx >= goalData.length) {
          element = { role: "bot", content: "", effect: "set_step", step: 21 };
          elements.push(element);
          writeMessages(elements);
        } else {
          let newMessages = [];
          let user_request = input;
          if (user_request && user_request != "") {
            let userMessage = { role: "user", content: input };
            newMessages.push(userMessage);
            setMessages([...messages, userMessage]);

            setInput("");
            setTimeout(() => {
              scrollToBottom();
            }, 100);

            setIsProcessing(true);
            updateGoalAction({
              session_id: session.id,
              goal_id: goalData[currentGoalIdx].id,
              user_request: user_request,
            })
              .unwrap()
              .then(function (res) {
                setIsProcessing(false);
                console.log(res);
                const goal_id = goalData[currentGoalIdx].id;

                const modifiedGoals = goalData.map((goal) => {
                  if (goal.id == goal_id) {
                    return { ...goal, action: res.action };
                  }
                  return goal;
                });
                setGoalData(modifiedGoals);
                element = {
                  role: "bot",
                  content: res.action,
                  type: "text",
                  effect: "type",
                  showLogo: true,
                };
                elements.push(element);
                newMessages.push(element);

                content = botMessages[8];
                element = {
                  role: "bot",
                  content: content,
                  type: "text",
                  effect: "type",
                  showLogo: false,
                };
                newMessages.push(element);

                let choices = [
                  { value: "Correct", text: "Correct", step: 17 },
                  { value: "More changes", text: "More changes", step: 19 },
                ];
                element = {
                  role: "bot",
                  content: content,
                  type: "radiogroup",
                  choices: choices,
                  effect: "type",
                  showLogo: true,
                };
                newMessages.push(element);
                elements.push(element);

                writeMessages(elements);
                triggerSendMessage(newMessages);
              });
          }
        }
      } else if (step == 21) {
        content =
          conclusionMessages[0] +
          "\n" +
          conclusionMessages[1] +
          "\n" +
          conclusionMessages[2] +
          "\n" +
          conclusionMessages[3];

        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: true,
        };
        elements.push(element);

        element = { role: "bot", content: "", effect: "delay", time: 3 };
        elements.push(element);

        content =
          conclusionMessages[4] +
          "\n" +
          conclusionMessages[5] +
          "\n" +
          conclusionMessages[6] +
          "\n" +
          conclusionMessages[7] +
          "\n" +
          conclusionMessages[8];

        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: false,
        };
        elements.push(element);

        element = { role: "bot", content: "", effect: "delay", time: 3 };
        elements.push(element);

        content =
          conclusionMessages[9] +
          "\n" +
          conclusionMessages[10] +
          "\n" +
          conclusionMessages[11] +
          "\n" +
          conclusionMessages[12] +
          "\n" +
          conclusionMessages[13] +
          "\n" +
          conclusionMessages[14];

        element = {
          role: "bot",
          content: content,
          type: "text",
          effect: "type",
          showLogo: false,
        };
        elements.push(element);

        element = { role: "bot", content: "", effect: "delay", time: 3 };
        elements.push(element);

        element = {
          role: "bot",
          content: conclusionMessages[15],
          type: "text",
          effect: "type",
          showLogo: true,
        };
        elements.push(element);

        writeMessages(elements);

        handleFinishSession();
      }

      console.log("goalData", goalData);

      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }, [step, forceRender]);

  const triggerSendMessage = (newMessages) => {
    setIsProcessing(true);
    sendSessionMessage({
      session_id: session.id,
      message: newMessages,
      is_finished: false,
      step: step,
      goal_idx: currentGoalIdx,
    })
      .unwrap()
      .then(function (res) {
        console.log(res);
        setIsProcessing(false);
      });
  };

  const handleSend = async () => {
    setStep(step + 1);
    setIsShowChat(false);
  };

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ block: "end" });
  };

  const handleContinue = () => {
    setIsContinue(false);
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const selectChoice = async (choice) => {
    setMessages((prevList) => {
      if (prevList.length === 0) return [choice]; // handle case where list is empty

      const newList = [...prevList]; // create a shallow copy
      newList[newList.length - 1].selected_choice = choice.value; // change the last item
      return newList;
    });
    setCurrentChoice(choice);
    setStep(choice.step);
    sendSessionMessage({ session_id: session.id, choice: choice.value })
      .unwrap()
      .then(function (res) {
        console.log(res);
        setIsProcessing(false);
      });
  };

  const handleFinishSession = () => {
    setSessionEnded(true);
    generateSessionSummary({ session_id: session.id })
      .unwrap()
      .then(function (res) {
        console.log(res);
      });
    scrollToBottom();
  };

  const handleFinish = () => {
    window.location.href = "/life-coach";
  };

  const handleChatContinue = () => {
    setForceRender(false);
  };

  return (
    <div className="life-coach-session-component">
      {isContinue == true ? (
        <>
          <SessionWelcomeBackComponent />
          <Row className="mt-4">
            <Col className="text-center">
              <Button
                className="btn-yellow mt-3"
                size="lg"
                onClick={() => handleContinue()}
              >
                CONTINUE
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {userSessionHistory && (
            <SessionMessageListComponent
              isShowChat={isTyping}
              messages={messages}
              userLogoName={userLogoName}
              selectChoice={selectChoice}
              handleContinue={handleChatContinue}
              forceRender={forceRender}
            />
          )}
          {sessionEnded == false && isProcessing == true ? (
            <SessionBotProcessingComponent />
          ) : (
            isShowChat && (
              <SessionChatInputComponent
                chatInputRef={chatInputRef}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onSend={handleSend}
              />
            )
          )}
          {sessionFinished == true && (
            <h6
              style={{
                textAlign: "center",
                color: "white",
                marginTop: "2rem",
                marginBottom: "4rem",
              }}
            >
              this session was already taken
            </h6>
          )}
          {isTyping ? (
            sessionEnded == true ? (
              <Row className="mt-4">
                <Col className="text-center">
                  <Button
                    className="btn-yellow mt-3"
                    ref={closeBtnRef}
                    size="lg"
                    onClick={() => handleFinish()}
                  >
                    CONTINUE
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row className="mt-4">
                <Col className="text-center">
                  <Button
                    className="btn-yellow mt-3"
                    ref={closeBtnRef}
                    size="lg"
                    onClick={() => handlePopupClose()}
                  >
                    PAUSE
                  </Button>
                </Col>
              </Row>
            )
          ) : (
            <></>
          )}
        </>
      )}
      <span ref={scrollRef}></span>
    </div>
  );
};

export default LifeCoachCustomSession2Component;
