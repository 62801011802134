import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Button } from 'react-bootstrap';
import './../css/life-coach-session-component.css'
import SessionMessageListComponent from './SessionMessageListComponent';
import SessionChatInputComponent from './SessionChatInputComponent';

import { useGetUserSessionMutation, useSendSessionMessageMutation, useGenerateSessionSummaryMutation } from '../slices/lifeCoachApiSlice';
import SessionBotProcessingComponent from './SessionBotProcessingComponent';
import SessionEndedComponent from './SessionEndedComponent';
import SessionWelcomeBackComponent from './SessionWelcomeBackComponent';

const allowed_type_list = ["radiogroup", "text", "comment"]

const LifeCoachSessionComponent = ({ session, handlePopupClose }) => {
    const user = useSelector(state => state.auth.user);
    const userLogoName = user.first_name.substring(0, 2).toUpperCase()
    const [userSessionHistory, setUserSessionHistory] = useState(null)
    const [questonElements, setQuestionElements] = useState(null)
    const [messages, setMessages] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [sessionFinished, setSessionFinished] = useState(false)
    const [sessionEnded, setSessionEnded] = useState(false)
    const [sessionPrepareEnded, setSessionPrepareEnded] = useState(false)
    const [isShowChat, setIsShowChat] = useState(true)
    const [isTyping, setIsTyping] = useState(false)
    const [isContinue, setIsContinue] = useState(false)

    const [input, setInput] = useState('');
    const [step, setStep] = useState(null)
    const [reflection, setReflection] = useState(null)
    const chatInputRef = useRef(null);
    const closeBtnRef = useRef(null);
    const scrollRef = useRef(null);

    const [getUserSession] = useGetUserSessionMutation()
    const [sendSessionMessage] = useSendSessionMessageMutation()
    const [generateSessionSummary] = useGenerateSessionSummaryMutation()

    const analyzeFormJson = (formJson) => {
        let elementList = []
        const pages = formJson.pages
        for (let i = 0; i < pages.length; i++) {
            let page = pages[i]
            let elements = page.elements
            if (elements) {
                for (let j = 0; j < elements.length; j++) {
                    let element = elements[j]
                    if (allowed_type_list.includes(element.type))
                        elementList.push(element)
                }
            }
        }
        console.log(elementList)
        return elementList;
    }

    const scrollToBottom = () => {
        scrollRef.current?.scrollIntoView({ block: "end" })
    }

    useEffect(() => {
        if (userSessionHistory == null) {
            getUserSession({ session_id: session.id }).unwrap().then(function (res) {
                if (res.is_finished == false) {
                    setUserSessionHistory(res)
                    let elementList = null
                    if (res.survey_form_json) {
                        elementList = analyzeFormJson(JSON.parse(res.survey_form_json))
                    } else {
                        elementList = analyzeFormJson(JSON.parse(session.survey_form_json))
                    }
                    setQuestionElements(elementList)
                    if (res.messages && res.messages.length > 0) {
                        setIsContinue(true)
                        setMessages(res.messages)
                        setStep(res.step + 1)
                    } else {
                        let element = elementList[0]
                        if (element.type == 'text' || element.type == 'comment') {
                            setMessages([{ role: 'bot', name: element.name, content: element.title, type: element.type, reflection: '' }]);
                        } else if (element.type == 'radiogroup') {
                            setMessages([{ role: 'bot', name: element.name, content: element.title, type: element.type, choices: element.choices, reflection: '' }]);
                        }
                        setStep(0)
                    }
                } else {
                    setSessionFinished(true)
                }
            })
        }
    }, []);

    useEffect(() => {
        const typeInitialMessage = async (element) => {
            setIsShowChat(false)
            setIsTyping(false)
            let msg = element.title
            if (messages.length > 1) {
                if (element.type == 'text' || element.type == 'comment') {
                    setMessages(prev => [...prev, { role: 'bot', name: element.name, content: '', type: 'text', reflection: '', showLogo: true }]);
                } else if (element.type == 'radiogroup') {
                    setMessages(prev => [...prev, { role: 'bot', name: element.name, content: '', type: 'text', choices: element.choices, reflection: '', showLogo: true }]);
                }
            } else {
                if (element.type == 'text' || element.type == 'comment') {
                    setMessages([{ role: 'bot', content: '', name: element.name, type: 'text', showLogo: true}]);
                } else {
                    setMessages([{ role: 'bot', content: '', name: element.name, choices: element.choices, type: 'text' , showLogo: true}]);
                }
            }
            if (reflection) {
                for (let char of reflection) {
                    setMessages(prev => {
                        let newMessages = [...prev];
                        newMessages[newMessages.length - 1].reflection += char;

                        return newMessages;
                    });

                    await new Promise(resolve => setTimeout(resolve, 25));
                }
                scrollToBottom()
            }
            setReflection(null)

            for (let char of msg) {
                setMessages(prev => {
                    let newMessages = [...prev];
                    newMessages[newMessages.length - 1].content += char;
                    return newMessages;
                });

                await new Promise(resolve => setTimeout(resolve, 25));
            }
            setMessages(prev => {
                let newMessages = [...prev];
                newMessages[newMessages.length - 1].type = element.type;

                return newMessages;
            });
            setIsTyping(true)
            setTimeout(() => {
                console.log("down")
                scrollToBottom()
            }, 100);
            if(element.type !== 'radiogroup'){
                setIsShowChat(true)
                setTimeout(() => {
                    if (chatInputRef.current) {
                        chatInputRef.current.focus()
                    }                    
                }, 200);
            }

        };

        if (messages && step != null) {
            try{
                typeInitialMessage(questonElements[step]);
            }catch(e){
                console.log(e)
            }
            setTimeout(() => {
                console.log("down")
                scrollToBottom()
            }, 100);
        }

        if (sessionPrepareEnded == true) {
            let element = { role: 'bot', name: 'final message', title: "Congratulations on Completing Session 1!  We've made meaningful progress in understanding the areas where you'd like to grow. You'll find a summary of our coaching session on the main life coach page. Your next steps are crucial for your growth journey:", type: 'text', reflection: '' }
            typeInitialMessage(element);

        }
    }, [step]);

    const handleSend = async () => {
        let message = messages[messages.length - 1]
        let questionMessage = null;
        if (messages.length > 0) {
            questionMessage = message
        }
        setIsProcessing(true)
        try {
            await sendSessionMessage({ session_id: session.id, message: message, is_reflection: false, is_finished: false }).unwrap()
            setIsProcessing(false)
        } catch (e) {
            console.log(e)
            setIsProcessing(false)
        }

        let newMessage = { role: 'user', content: input }
        setMessages([...messages, newMessage]);
        setInput('');
        setTimeout(() => {
            scrollToBottom()
        }, 100);
        let isFinished = false
        if (questonElements.length <= step + 1) {
            isFinished = true
            console.log("messages", messages)
        }
        setIsProcessing(true)
        try {
            const res = await sendSessionMessage({ session_id: session.id, message: newMessage, question: questionMessage, is_reflection: true, is_finished: isFinished, step: step }).unwrap();
            if (res.message == 'ok') {
                if (res.reflection) {
                    setReflection(res.reflection)
                }
            }
            setIsProcessing(false)
        } catch (e) {
            console.log(e)
            setIsProcessing(false)
        }
        if (isFinished == false) {
            setStep(step + 1)
        } else {
            handleFinishSessionPrepare()
        }

    }

    const selectChoice = async (choice) => {
        let message = messages[messages.length - 1]
        message.selected_choice = choice.value
        let questionMessage = message;
        setIsProcessing(true)
        try {
            await sendSessionMessage({ session_id: session.id, message: message, is_reflection: false, is_finished: false }).unwrap()
            setIsProcessing(false)
        } catch (e) {
            console.log(e)
            setIsProcessing(false)
        }
        let isReflection = true
        let choiceText = choice.text
        if (choiceText.toLowerCase() == 'other') {
            choiceText = ''
            isReflection = false
        }

        let newMessage = { role: 'user', content: choiceText }
        setMessages([...messages, newMessage]);
        setInput('');
        let isFinished = false
        setTimeout(() => {
            scrollToBottom()
        }, 100);
        if (questonElements.length <= step + 1) {
            isFinished = true
            console.log("messages", messages)
        }

        setIsProcessing(true)
        try {
            const res = await sendSessionMessage({ session_id: session.id, message: newMessage, question: questionMessage, is_reflection: isReflection, is_finished: isFinished, step: step }).unwrap()
            if (res.reflection) {
                setReflection(res.reflection)
            }
            setIsProcessing(false)
        } catch (e) {
            console.log(e)
            setIsProcessing(false)
        }

        if (isFinished == false) {
            setStep(step + 1)
        } else {
            handleFinishSessionPrepare()
        }
    }

    const handleFinishSessionPrepare = () => {
        setIsShowChat(false)
        setSessionPrepareEnded(true)
        setStep(null)
        generateSessionSummary({ session_id: session.id }).unwrap().then(function (res) { console.log(res) })
        scrollToBottom()
    }

    const handleFinishSession = () => {
        setSessionEnded(true)
        setSessionPrepareEnded(false)
        setTimeout(() => {
            scrollToBottom()
        }, 100);
    }

    const handleContinue = () => {
        setIsContinue(false)
        scrollToBottom()
    }
    const handleFinish = () => {
        window.location.href = "/life-coach"
    }

    return (
        <div className='life-coach-session-component'>
            {isContinue == true ?
                <>
                    <SessionWelcomeBackComponent />
                    <Row className='mt-4'>
                        <Col className="text-center">
                            <Button className='btn-yellow mt-3' size="lg" onClick={() => handleContinue()} >
                                CONTINUE
                            </Button>
                        </Col>
                    </Row>

                </> :
                <>
                    {userSessionHistory &&
                        <SessionMessageListComponent isShowChat={isTyping} messages={messages} userLogoName={userLogoName} selectChoice={selectChoice} />
                    }
                    {sessionEnded == true ?
                        <SessionEndedComponent order={session.order} />
                        :
                        isProcessing == true ?
                            <SessionBotProcessingComponent /> :
                            isShowChat && sessionPrepareEnded == false && <SessionChatInputComponent chatInputRef={chatInputRef} value={input} onChange={(e) => setInput(e.target.value)} onSend={handleSend} />
                    }
                    {sessionFinished == true &&
                        <h6 style={{ textAlign: 'center', color: 'white', marginTop: '2rem', marginBottom: '4rem' }}>this session was already taken</h6>
                    }

                    {sessionPrepareEnded == true?
                        <Row className='mt-4'>
                        <Col className="text-center">
                            <Button className='continue-btn mt-3' ref={closeBtnRef} size="lg" onClick={() => handleFinishSession()} >
                                CONTINUE
                            </Button>
                        </Col>
                    </Row>
                    : sessionEnded == true ?
                        <Row className='mt-4'>
                            <Col className="text-center">
                                <Button className='btn-yellow mt-3' ref={closeBtnRef} size="lg" onClick={() => handleFinish()} >
                                    CONTINUE
                                </Button>
                            </Col>
                        </Row>
                        :
                        <Row className='mt-4'>
                            <Col className="text-center">
                                <Button className='btn-yellow mt-3' ref={closeBtnRef} size="lg" onClick={() => handlePopupClose()} >
                                    PAUSE
                                </Button>
                            </Col>
                        </Row>
                    }

                </>

            }
            <span ref={scrollRef}></span>

        </div>
    )

}

export default LifeCoachSessionComponent;