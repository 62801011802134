import React from 'react';

const PublicLayout = () => {
  window.location.href = 'https://mosaiclifetech.com/';

  return (
    <>
    </>
  );
};

export default PublicLayout;
