import React from 'react'

const ShareLayout = ({ children }) => {

    return (
        <>
            <div className="quize_dashboard">
                <div className="topbar" style={{ left: 0 }}>
                    {/* <!-- Navbar --> */}
                    <nav className="navbar-custom">
                        <ul className="list-unstyled topbar-nav mb-0 d-flex align-items-center">
                            <li className='px-4'>
                                <a href='/register' className='assessment-tool' style={{ fontSize: '1.5rem', color: '#d7a03e' }}>
                                    Sign Up
                                </a>

                            </li>
                            <li className='px-4'>
                                <a href='/login' className='assessment-tool' style={{ fontSize: '1.5rem', color: 'white' }}>
                                    Sign In
                                </a>

                            </li>
                        </ul>
                    </nav>
                </div>

                {/* <!-- Page Content--> */}
                <div className="page-content">
                    {children}
                </div>
            </div>

        </>

    )

}
export default ShareLayout;
