import * as React from "react";
import logoWhiteSvg from "./../../assets/images/logo-white.svg";
// import facebookSvg from './../../assets/images/facebook.svg'
// import twitterSvg from './../../assets/images/twitter.svg'
// import instagramSvg from './../../assets/images/instagram.svg'
// import youtubeSvg from './../../assets/images/youtube.svg'
import "./../../assets/css/footer.css";

import { useGetLatestReleaseQuery } from "../../features/releases/slices/releasesApiSlice";

export default function MainFooter(props) {
  const { data: latestRelease, isLoading } = useGetLatestReleaseQuery();

  return (
    <>
      {/* <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
    <div className="col-md-4 d-flex align-items-center">
      <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
      <img src={logoWhiteSvg} width={80} alt="" />
      </a>
      <span className="mb-3 mb-md-0 text-muted">© 2022 Company, Inc</span>
    </div>

    <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
      <li className="ms-3"><a className="text-muted" href="#">Home</a></li>
      <li className="ms-3"><a className="text-muted" href="#">Terms of Service</a></li>
      <li className="ms-3"><a className="text-muted" href="#">Privacy Policy</a></li>
      <li className="ms-3"><a className="text-muted" href="#">Privacy Policy</a></li>
      <li className="ms-3"><a className="text-muted" href="#">Privacy Policy</a></li>
    </ul>
  </footer>         */}
      <footer className="d-flex flex-wrap justify-content-between align-items-center border-top">
        <p className="col-md-4 mb-0">
          &copy; 2024 Mosaic Life Tech LLC. All rights reserved.{" "}
          {latestRelease && latestRelease.version_number && (
            <a href="/releases" target="_blank" className="version-link">
              Version {latestRelease.version_number}
            </a>
          )}
        </p>

        <a
          href="https://mosaiclifetech.com"
          className="logo-link col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
        >
          <img src={logoWhiteSvg} alt="" />
        </a>

        <ul className="nav col-md-4 justify-content-end">
          <li className="nav-item">
            <a
              href="https://mosaiclifetech.com"
              target="_blank"
              className="nav-link px-2"
            >
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="/terms-of-service" target="_blank" className="nav-link px-2">
              Terms of Service
            </a>
          </li>
          <li className="nav-item">
            <a href="/privacy" target="_blank" className="nav-link px-2">
              Privacy Policy
            </a>
          </li>
          <li className="nav-item">
            <a
              href="https://mosaiclifetech.com/support"
              target="_blank"
              className="nav-link px-2"
            >
              Support
            </a>
          </li>
          <li className="nav-item">
            <a
              href="https://mosaiclifetech.com/feedback"
              target="_blank"
              className="nav-link px-2"
            >
              Feedback
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}
