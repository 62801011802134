import React, { useEffect } from 'react';
import './../css/session-tag-component.css'

const SessionTagComponent = ({ choice, selectChoice, selectedChoice }) => {
    
    useEffect(() => {
        window.scrollBy(0, window.innerHeight);
    }, []);

    const handleClick = (choice) => {
        if(selectedChoice == null){
            selectChoice(choice)
        }
        console.log(choice)
    }
    return (
        <div className={selectedChoice == choice.value?`session-tag-component active`:`session-tag-component`} onClick={()=>handleClick(choice)}>
            {choice.text}
        </div>

    )

}

export default SessionTagComponent;