import React, { Suspense } from "react";
import {
  Route,
  Routes as Switch,
  Navigate,
  useLocation,
  Redirect,
} from "react-router-dom";

import Loading from "./components/Loading";

const RouterCombiner = ({
  routes,
  auth /* Its Only Use For Now,I Handle It With ReduxStore */,
}) => {
  const RoutesMap = routes.map(
    ({
      Private,
      Public,
      exact = true,
      Layout = ({ children }) => <>{children}</>,
      Component,
      path,
    }) => {
      const ComponentWithLayout = () => {
        const { state } = useLocation();
        if (Public == true) {
          return (
            <Layout>
              <Suspense fallback={<Loading />}>
                <Component />
              </Suspense>
            </Layout>
          );
        }
        if (
          auth.user &&
          auth.user.is_first_login &&
          window.location.pathname !== "/accept-terms"
        ) {
          return (
            <Navigate
              to={{ pathname: "/accept-terms" }}
              state={{ from: window.location.pathname }}
            />
          );
        } else if (
          auth.user &&
          auth.user.is_first_login == false &&
          auth.user.user_role_type == "FREE" &&
          window.location.pathname !== "/pricing" &&
          window.location.pathname !== "/success" &&
          window.location.pathname !== "/all-set"
        ) {
          return (
            <Navigate
              to={{ pathname: "/pricing" }}
              state={{ from: window.location.pathname }}
            />
          );
        } else if (
          auth.user &&
          auth.user.is_first_login == false &&
          auth.user.user_role_type !== "FREE" &&
          window.location.pathname === "/pricing"
        ) {
          return <Navigate to={{ pathname: "/dashboard" }} />;
        } else if (
          Private &&
          !auth.token &&
          window.location.pathname !== "/login"
        ) {
          return (
            <Navigate
              to={{ pathname: "/login" }}
              state={{ from: window.location.pathname }}
            />
          );
        } else if ((Private === false || Private === undefined) && auth.token) {
          if (state && state.from) {
            return <Navigate to={{ pathname: state.from }} />;
          } else {
            return <Navigate to={{ pathname: "/dashboard" }} />;
          }
        } else {
          return (
            <Layout>
              <Suspense fallback={<Loading />}>
                <Component />
              </Suspense>
            </Layout>
          );
        }
      };

      return (
        <Route
          key={path}
          exact={exact}
          element={<ComponentWithLayout />}
          path={path}
        />
      );
    }
  );

  // RoutesMap.push(<Route key="*" path="*" element={<Navigate to="/404" />} />)

  return <Switch> {RoutesMap}</Switch>;
};

export default RouterCombiner;
