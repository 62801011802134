import PublicLayout from './Public'
import AuthLayout from './Auth'
import VerifyLayout from './Verify'
import MainLayout from './MainV2'
import MainLayoutTemp from './Main'
import BlankLayout from './Blank'
import SetupLayout from './Setup'
import ShareLayout from './Share'
import TermsLayout from './Terms'
import LeadMagnetLayout from './LeadMagnet'


const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
  blank: BlankLayout,
  setup: SetupLayout,
  verify:VerifyLayout,
  share:ShareLayout,
  terms:TermsLayout,
  leadMagnet: LeadMagnetLayout
}

export default Layouts;