import React, { useEffect } from 'react';
import botPng from './../../../assets/images/bot.png'
import './../css/session-bot-processing-component.css'

const SessionBotProcessingComponent = () => {
    useEffect(() => {
        window.scrollBy(0, window.innerHeight);
    }, []);
    return (
        <div className='session-bot-processing-component'>
            <div className='message bot'>
                <img className='bot-logo' src={botPng}></img>
                <div class="dots">
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                </div>
            </div>
        </div>

    )

}

export default SessionBotProcessingComponent;