import React, { useState, useEffect } from 'react';
import './../css/session-welcome-back-component.css'

const SessionWelcomeBackStartNewComponent = () => {
    return (
        <div className='session-welcome-back-component'>
            <h5>Welcome Back!</h5>
            <br></br>
            <p>Life can be full of interruptions, but don't worry! We've saved all your progress. You can pick up right where you left off and continue on your transformative journey with Mosaic Life.</p>
            <br></br>
            <p>Please press 'Continue' if you'd like to continue our previous conversation, or press 'Start new' if you'd prefer to start a new conversation.</p>
        </div>

    )

}

export default SessionWelcomeBackStartNewComponent