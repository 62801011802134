import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./../../assets/css/login-register.css";
import CookieConsentComponent from "../../components/CookieConsentComponent";
import MainFooter from "../../components/main/MainFooter";
import SignupStepComponent from "../../components/SignupStepComponent";

import { Dropdown } from "react-bootstrap";
import avatarPng from "./../../assets/images/avatar.png";
import Image from "react-bootstrap/Image";
import { useLogoutMutation } from "../../features/auth/slices/authApiSlice";
import { LOG_OUT } from "../../redux/constants/actionTypes";

const AuthLayout = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const handleLogOut = async (e) => {
    try {
      const responseData = await logout().unwrap();
    } catch (err) {
      console.log("err", err);
    }
    dispatch({ type: LOG_OUT });
    navigate("/", { replace: true });
  };

  return (
    <>
      {window.location.pathname != "/register" &&
        window.location.pathname != "/login" && (
          <div className="topbar" style={{ right: 0, top: "1rem" }}>
            {/* <!-- Navbar --> */}
            <nav className="navbar-custom">
              <ul className="list-unstyled topbar-nav mb-0 d-flex align-items-center">
                <li></li>
              </ul>
              <Dropdown style={{ textAlign: "right", marginTop: "1rem" }}>
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                  }}
                >
                  <span
                    className="mr-3 hidden-sm text-white"
                    style={{ fontSize: "1.5rem" }}
                  >
                    {user ? user.first_name + " " + user.last_name : ""}{" "}
                  </span>{" "}
                  &nbsp;&nbsp;
                  <Image
                    src={avatarPng}
                    width="50"
                    alt="profile-user"
                    roundedCircle
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ textAlign: "center" }}>
                  <Dropdown.Item
                    style={{ padding: "10px 20px", width: "auto" }}
                    onClick={() => handleLogOut()}
                  >
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </nav>
          </div>
        )}

      {window.location.pathname == "/register" && (
        <SignupStepComponent step={0} />
      )}
      {window.location.pathname == "/verification" && (
        <SignupStepComponent step={1} />
      )}
      {window.location.pathname == "/accept-terms" && (
        <SignupStepComponent step={2} />
      )}
      {window.location.pathname == "/pricing" && (
        <SignupStepComponent step={3} />
      )}
      {window.location.pathname == "/all-set" && (
        <SignupStepComponent step={4} />
      )}
      <div className="login-register">
        <div className="container">
          <div className="login-acc no-shadown">{children}</div>
        </div>
      </div>
      <MainFooter />
      <CookieConsentComponent />
    </>
  );
};

export default AuthLayout;
