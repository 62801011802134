export const SET_CREDENTIALS = 'SET_CREDENTIALS'
export const LOG_OUT = 'LOG_OUT'
export const ACCEPT_TERMS = 'ACCEPT_TERMS'
export const SET_USER_ROLE = 'SET_USER_ROLE'
export const SET_PROFILE = 'SET_PROFILE'
export const UPDATE_AVATAR = 'UPDATE_AVATAR'
export const SET_UPGRADE_REMINDER_AT = 'SET_UPGRADE_REMINDER_AT'
export const SET_APP_LOGOUT_TIME = 'SET_APP_LOGOUT_TIME'
export const SET_ENABLED_MY_UPLOAD_FILES = 'SET_ENABLED_MY_UPLOAD_FILES'
export const SET_ENABLED_REWARDS = 'SET_ENABLED_REWARDS'
export const SET_CHECKED_USER_STATUS = 'SET_CHECKED_USER_STATUS'
export const SET_OPENAI_STATUS_CHECK_TIME = 'SET_OPENAI_STATUS_CHECK_TIME'

export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_JOURNAL_TEMPLATES = 'SET_JOURNAL_TEMPLATES'
export const SET_DEFAULT_TEMPLATE_ID = 'SET_DEFAULT_TEMPLATE_ID'

export const SET_WATCHED_LIFE_COACH_TUTORIAL = 'SET_WATCHED_LIFE_COACH_TUTORIAL'
export const SET_LIFE_COACH_SESSIONS = 'SET_LIFE_COACH_SESSIONS'
export const SET_LAST_SESSION_ORDER = 'SET_LAST_SESSION_ORDER'

export const SET_OPEN = 'SET_OPEN'
export const SET_CLOSE = 'SET_CLOSE'

export const SET_MOBILE_MODE = 'SET_MOBILE_MODE'
export const SET_DESKTOP_MODE = 'SET_DESKTOP_MODE'

export const SET_USER_INFORMATION_STATE = 'SET_USER_INFORMATION_STATE'
export const SET_USER_CHAT_HISTORY = 'SET_USER_CHAT_HISTORY'
export const UPDATE_USER_CHAT_HISTORY = 'UPDATE_USER_CHAT_HISTORY'
export const UPDATE_CHAT_STATUS = 'UPDATE_CHAT_STATUS'
export const UPDATE_CHAT_OPEN = 'UPDATE_CHAT_OPEN'
export const UPDATE_SUGGEST_QUESTIONS = 'UPDATE_SUGGEST_QUESTIONS'
export const UPDATE_CREATE_GOALS = 'UPDATE_CREATE_GOALS'
export const RESET_SUGGESTIONS = 'RESET_SUGGESTIONS'


export const SET_NOTIFICATION_STATE = 'SET_NOTIFICATION_STATE'
export const SET_COACH_NOTIFICATION = 'SET_COACH_NOTIFICATION'
export const SET_AFFIRMATION_NOTIFICATION = 'SET_AFFIRMATION_NOTIFICATION'
export const SET_JOURNAL_NOTIFICATION = 'SET_JOURNAL_NOTIFICATION'
