import { Col, Row, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import { useSelector } from "react-redux";
import config from '../config/config';
import moment from 'moment-timezone';
import './css/badge-component-in-popup.css'

const BadgeComponentInPopup = ({ userBadge }) => {
    const userTimezone = useSelector(state => state.auth.user.default_timezone);

    const convertUTCToUserTimezone = (date) => {
        let utcDate = moment.utc(date);
        let userTime = utcDate.tz(userTimezone).format("DD-MMMM-yyyy");
        return userTime;
    }
    return (
        <div className='badge-component-in-popup'>
            <Row>
                <Col className='text-center'>
                    <Image src={config.serverHost + userBadge.badge.img_file} rounded width={150} />
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col className='text-center'>
                    <h5>{userBadge.badge.name}</h5>
                </Col>
            </Row>
            <Row>
                <Col className='text-center mt-4'>
                    <p>{userBadge.badge.description}</p>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className='d-flex justify-content-center'>
                    <div className='earned-component'>
                        {userBadge.points_awarded && userBadge.points_awarded > 0 ?
                            <p>Earned {userBadge.points_awarded} points</p>:
                            <p></p>
                        }
                        <p className='earned-date'>{convertUTCToUserTimezone(userBadge.created_at)}</p>
                    </div>
                </Col>
            </Row>

        </div>

    )


}

export default BadgeComponentInPopup