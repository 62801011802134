import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Loading from '../../../components/Loading';
import {
  SET_CATEGORIES,
  SET_USER_ROLE,
  LOG_OUT,
  SET_APP_LOGOUT_TIME,
  SET_ENABLED_MY_UPLOAD_FILES,
  SET_ENABLED_MY_JOURNAL,
  SET_JOURNAL_TEMPLATES,
  SET_DEFAULT_TEMPLATE_ID,
  SET_ENABLED_REWARDS,
  SET_LIFE_COACH_SESSIONS,
  SET_LAST_SESSION_ORDER,
  SET_WATCHED_LIFE_COACH_TUTORIAL,
  SET_OPENAI_STATUS_CHECK_TIME,
  SET_USER_INFORMATION_STATE,
} from '../../../redux/constants/actionTypes';
import { useGetAnalyticsQuery } from '../../user/slices/userApiSlice';
import { useGetUserInformationStateQuery } from '../../coach/slices/coachApiSlice';

export default function Analytics() {
  var timer = null;
  const categories = useSelector((state) => state.quiz.categories);
  const userInfoState = useSelector((state) => state.coach.userInformationState);
  const user = useSelector((state) => state.auth.user);
  const appLogoutTime = useSelector((state) => state.auth.appLogoutTime);
  const openAiStatusCheckTime = useSelector(
    (state) => state.auth.openAiStatusCheckTime
  );
  const isEnabledMyUploadFiles = useSelector(
    (state) => state.auth.isEnabledMyUploadFiles
  );
  const isEnabledRewards = useSelector((state) => state.auth.isEnabledRewards);
  const journalTemplates = useSelector((state) => state.quiz.journalTemplates);
  const defaultTemplateId = useSelector(
    (state) => state.quiz.defaultTemplateId
  );
  const sessions = useSelector((state) => state.session.sessions);
  const lastSessionOrder = useSelector(
    (state) => state.session.lastSessionOrder
  );
  const isWatchedLifeCoachTutorial = useSelector(
    (state) => state.auth.isWatchedLifeCoachTutorial
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: analytics, isLoading, error } = useGetAnalyticsQuery();

  const {
    data: userInformationState,
    isLoading: isLoadingUserInformationState,
    error: errorUserInformationState,
  } = useGetUserInformationStateQuery();

  useEffect(() => {
    if (analytics) {
      const onLogout = () => {
        dispatch({ type: LOG_OUT });
        navigate('/login', {
          state: {
            message:
              " It seems you've been inactive for a while, so we've logged you out of Mosaic Life to keep your information safe. Please log back in to continue using Mosaic Life!",
          },
        });
      };
      const resetTimer = () => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(onLogout, analytics.app_logout_time * 60000);
      };

      const handleActivity = () => {
        resetTimer();
      };

      if (analytics.categories && analytics.categories !== categories) {
        dispatch({ type: SET_CATEGORIES, payload: analytics.categories });
      }
      if (
        analytics.user &&
        user &&
        (analytics.user.user_role_type !== user.user_role_type ||
          analytics.user.is_daily_reminder_checked !==
            user.is_daily_reminder_checked)
      ) {
        dispatch({ type: SET_USER_ROLE, payload: analytics.user });
      }

      if (
        analytics.app_logout_time &&
        analytics.app_logout_time !== appLogoutTime
      ) {
        resetTimer();
        dispatch({
          type: SET_APP_LOGOUT_TIME,
          payload: analytics.app_logout_time,
        });
      }

      if (
        analytics.openai_status_check_time &&
        analytics.openai_status_check_time !== openAiStatusCheckTime
      ) {
        resetTimer();
        dispatch({
          type: SET_OPENAI_STATUS_CHECK_TIME,
          payload: analytics.openai_status_check_time,
        });
      }

      if (
        analytics.is_enabled_my_upload_files !== null &&
        analytics.is_enabled_my_upload_files !== isEnabledMyUploadFiles
      ) {
        dispatch({
          type: SET_ENABLED_MY_UPLOAD_FILES,
          payload: analytics.is_enabled_my_upload_files,
        });
      }

      if (
        analytics.journal_templates !== null &&
        analytics.journal_templates !== journalTemplates
      ) {
        dispatch({
          type: SET_JOURNAL_TEMPLATES,
          payload: analytics.journal_templates,
        });
      }

      if (
        analytics.is_enabled_rewards !== null &&
        analytics.is_enabled_rewards !== isEnabledRewards
      ) {
        dispatch({
          type: SET_ENABLED_REWARDS,
          payload: analytics.is_enabled_rewards,
        });
      }

      if (
        analytics.default_template_id !== null &&
        analytics.default_template_id !== defaultTemplateId
      ) {
        dispatch({
          type: SET_DEFAULT_TEMPLATE_ID,
          payload: analytics.default_template_id,
        });
      }

      if (analytics.sessions && analytics.sessions !== sessions) {
        dispatch({
          type: SET_LIFE_COACH_SESSIONS,
          payload: {
            sessions: analytics.sessions,
            goalCount: analytics.goal_count,
            lastSessionOrder: analytics.last_session_order,
            isActionPlanAccepted: analytics.is_action_plan_accepted,
          },
        });
      }
      // if (analytics.last_session_order && analytics.last_session_order !== lastSessionOrder) {
      //     dispatch({ type: SET_LAST_SESSION_ORDER, payload: analytics.last_session_order })
      // }

      if (
        analytics.watched_life_coach_tutorial &&
        analytics.watched_life_coach_tutorial !== isWatchedLifeCoachTutorial
      ) {
        dispatch({
          type: SET_WATCHED_LIFE_COACH_TUTORIAL,
          payload: analytics.watched_life_coach_tutorial,
        });
      }

      document.addEventListener('mousemove', handleActivity);
      document.addEventListener('keydown', handleActivity);
      resetTimer(); // Start the initial timer
      return () => {
        clearTimeout(timer);
        document.removeEventListener('mousemove', handleActivity);
        document.removeEventListener('keydown', handleActivity);
      };
    }
  }, [analytics, appLogoutTime]);

  useEffect(() => {
    if (userInformationState) {
      if (userInformationState) {
        dispatch({
          type: SET_USER_INFORMATION_STATE,
          payload: {
            userInformationState: userInformationState.message
          },
        });
      }
    }
  });

  // if (isLoading) {
  //     return <Loading />
  // }

  if (error) {
    if (error.status == 401) {
      dispatch({ type: LOG_OUT });
      navigate('/login', { replace: true });
    }
  }
}
